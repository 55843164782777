import { MUIDataTableState } from 'mui-datatables';
import { NumberParam, StringParam, withDefault, DecodedValueMap } from 'use-query-params';

import { DivestActionBlob, MergeActionBlob } from './compare';

export interface CaseHistory {
  created: string;
  author: string;
  actionType: string;
  action: DivestActionBlob | MergeActionBlob;
  downloaded: boolean;
  id: number;
}

export interface ReconciliationHistoryTableProps {
  caseHistory: CaseHistory[];
  isLoading: boolean;
  totalCount: number;
  query: DecodedValueMap<typeof queryConfig>;
  onChangePage: (newPage: number) => void;
  onChangeRowsPerPage: (numberOfPages: number) => void;
  onChangeFilter: (filterName: string, filterList: MUIDataTableState['filterList']) => void;
  onResetFilters: () => void;
  mutateHistoryRows: (newData: CaseHistory[]) => void;
}

export const queryConfig = {
  page: withDefault(NumberParam, 0),
  rowsPerPage: withDefault(NumberParam, 10),
  author: withDefault(StringParam, ''),
  actionType: withDefault(StringParam, ''),
  minDate: withDefault(StringParam, ''),
  maxDate: withDefault(StringParam, ''),
  downloaded: withDefault(StringParam, ''),
};

export interface HistoryTableToolbarProps {
  // @types/mui-datatables doesn't currently export the selectedRows interface, so it was copied here manually
  selectedRows: { data: Array<{ index: number; dataIndex: number }>; lookup: { [key: number]: boolean } };
  data: CaseHistory[];
  mutateHistoryRows: (newData: CaseHistory[]) => void;
  resetRowsSelected: () => void;
}

export interface MergeCaseCsvRow {
  Id: string;
  Master_Account__c: string;
  Create_Case_Status__c: string;
  Create_Case_Subject__c: string;
}

export interface DivestCaseCsvRow {
  RecordTypeId: string;
  Status: string;
  Subject: string;
  AccountId: string;
  Divest_To_Account__c: string;
  HistoryId: number;
}

export interface DivestSuccessLog {
  ID: string;
  RECORDTYPEID: string;
  STATUS: string;
  SUBJECT: string;
  ACCOUNTID: string;
  DIVEST_TO_ACCOUNT__C: string;
  HISTORYID: number;
}

export interface Step2DivestCaseCsvRow {
  Case__c: string;
  Division__c: string;
}
