import React, { FC, CSSProperties } from 'react';
import {
  Droppable,
  Draggable,
  DraggingStyle,
  NotDraggingStyle,
  DraggableProvided,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd';
import memoizeOne from 'memoize-one';
import { Table, TableBody, TableHead, TableRow, TableCell, makeStyles, Theme } from '@c2fo/react-components';

import { DivisionTableDroppableProps } from '../../schemas/compare';
import { Division } from '../../schemas/lookup';
import { AnimatedPulseIcon } from '../../components';
import { formatDate } from '../../helpers/dateTime';

const getItemStyle = (
  isDragging: boolean,
  isSelected: boolean,
  isGhosting: boolean,
  draggableStyle?: DraggingStyle | NotDraggingStyle
): CSSProperties => ({
  userSelect: 'none',
  width: '100%',
  // change background colour if ghosting, selected or dragging
  background: isGhosting ? '#FAFBFC' : isSelected || isDragging ? 'lightblue' : 'white',
  display: isDragging ? 'table' : 'table-row',
  tableLayout: 'fixed',
  // styles we need to apply on draggables
  ...draggableStyle,
});

// Checks for selected divisions, and if they're in the current business group.
const getSelectedMap = memoizeOne(selectedDivisionIds =>
  selectedDivisionIds.reduce((previous: any, current: any) => {
    previous[current] = true;
    return previous;
  }, {})
);

export const DivisionTableDroppable: FC<DivisionTableDroppableProps> = ({
  accountId,
  businessId,
  divisions,
  isResolved,
  selectedDivisionIds,
  draggingDivisionId,
  selectedColumns,
  multiSelectTo,
  toggleSelection,
  toggleSelectionInGroup,
}) => {
  const classes = useStyles();

  const primaryButton = 0;
  const onClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, divisionId: string) => {
    if (isResolved) return;
    if (event.defaultPrevented) return;
    if (event.button !== primaryButton) return;

    event.preventDefault();
    event.currentTarget.focus();
    performAction(event, divisionId);
  };
  const onKeyDown = (event: React.KeyboardEvent, provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
    if (event.defaultPrevented) return;
    if (snapshot.isDragging) return;
    if (event.key !== 'Enter') return;

    event.preventDefault();
    const divisionId = provided.draggableProps['data-rbd-draggable-id'];
    performAction(event, divisionId);
  };

  const wasToggleInSelectionGroupKeyUsed = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent> | React.KeyboardEvent
  ) => {
    const isUsingWindows = navigator.platform.indexOf('Win') >= 0;
    return isUsingWindows ? event.ctrlKey : event.metaKey;
  };
  const wasMultiSelectKeyUsed = (event: React.MouseEvent<HTMLDivElement, MouseEvent> | React.KeyboardEvent) =>
    event.shiftKey;
  const performAction = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent> | React.KeyboardEvent,
    divisionId: string
  ) => {
    if (wasMultiSelectKeyUsed(event)) {
      multiSelectTo(divisionId, accountId, businessId);
      return;
    }
    if (wasToggleInSelectionGroupKeyUsed(event)) {
      toggleSelectionInGroup(divisionId, accountId, businessId);
      return;
    }
    toggleSelection(divisionId);
  };

  return (
    <Droppable droppableId={`${accountId}|${businessId}`} type="division">
      {(provided, snapshot) => (
        <Table style={{ tableLayout: 'fixed', backgroundColor: 'white' }}>
          <TableHead className={classes.tableHead}>
            <TableRow>
              {selectedColumns.map(col => (
                <TableCell key={col.name} className={getTableCellClass(classes, col.classNames)}>
                  {col.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody
            ref={provided.innerRef}
            style={{ minHeight: '30px', display: divisions.length ? 'table-row-group' : 'block' }}
          >
            {divisions.map((item, index) => {
              const isSelected = Boolean(getSelectedMap(selectedDivisionIds)[item.uuid]);
              const isGhosting = Boolean(isSelected && draggingDivisionId && draggingDivisionId !== item.uuid);
              return (
                <Draggable key={item.uuid} draggableId={item.uuid} index={index} isDragDisabled={isResolved}>
                  {(provided, snapshot) => (
                    <TableRow
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(snapshot.isDragging, isSelected, isGhosting, provided.draggableProps.style)}
                      className={classes.tableRow + ` ${isGhosting && classes.isGhosting}`}
                      onClick={event => onClick(event, item.uuid)}
                      onKeyDown={event => onKeyDown(event, provided, snapshot)}
                    >
                      {selectedColumns.map(col => (
                        <TableCell key={col.name} className={getTableCellClass(classes, col.classNames)}>
                          {getTableCellContent(col.name, item)}
                        </TableCell>
                      ))}
                    </TableRow>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </TableBody>
        </Table>
      )}
    </Droppable>
  );
};

const getTableCellClass = (classes: Record<string, string>, classNames?: string[]) => {
  if (classNames?.length) {
    const classList = classNames.map(className => classes[className]);
    return classList.join(' ');
  }
  return '';
};

const getTableCellContent = (columnName: string, rowData: Division) => {
  const cellData = rowData[columnName as keyof Division];
  switch (columnName) {
    case 'participationDate':
      return <AnimatedPulseIcon participationDate={cellData as string} />;
    case 'createdDate':
      return formatDate(new Date(cellData as string));
    default:
      return cellData;
  }
};

const useStyles = makeStyles<Theme>(() => ({
  tableHead: {
    '&>tr>th': {
      borderBottom: '1px solid #e0e0e0 !important',
    },
  },
  tableRow: {
    '&:focus': {
      outline: '-webkit-focus-ring-color auto 1px',
    },
    '&>td': {
      overflow: 'hidden',
    },
  },
  isGhosting: {
    '&>td': {
      color: 'darkgrey',
    },
  },
  wideColumn: {
    width: '25%',
  },
  narrowColumn: {
    width: '10%',
  },
  taxIdColumn: {
    width: '150px',
  },
  participatedColumn: {
    width: '110px',
  },
  createdColumn: {
    width: '120px',
  },
  countryColumn: {
    width: '75px',
  },
  alignCenter: {
    textAlign: 'center',
  },
}));
