import React, { ReactNode } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import qs from 'qs';

import { setToken } from '../../helpers/auth';

type StoreTokenProps = {
  children: ReactNode;
};

/**
 * StoreToken is a simple path-parsing component to extract `token` from queryParams if it exists,
 * store it as the auth token, and redirect to a path without the token.
 *
 * <modified for smushatron, original yoinked from mono repo react-services>
 */
export function StoreToken({ children }: StoreTokenProps) {
  const location = useLocation();
  const queryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    strictNullHandling: true,
  });

  if (typeof queryParams?.token === 'string') {
    setToken(queryParams.token);
    delete queryParams.token;
    const searchSansToken = qs.stringify(queryParams, {
      strictNullHandling: true,
    });
    return <Redirect to={{ pathname: location.pathname, search: searchSansToken }} />;
  }

  /**
   * TypeScript complains if `children` isn't either wrapped in a Fragment
   * or typecasted to ReactElement<any>. This is a common/well-known issue.
   */
  return <>{children}</>;
}
