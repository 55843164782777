import React, { FC, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box } from '@c2fo/react-components';
import { createGlobalState, useList, useUpdateEffect } from 'react-use';
import { MUIDataTableColumn } from 'mui-datatables';

import { LookupForm } from './LookupForm';
import { LookupTable } from './LookupTable';
import { ComparisonPanel } from './ComparisonPanel';
import { mockDivisionData, mockAccountData } from '../../helpers/mockData';
import { Account, LookupRouteParams, LookupTableData } from '../../schemas/lookup';

const useGlobalColumns = createGlobalState<MUIDataTableColumn[]>();

export const LookupPage: FC = () => {
  const history = useHistory();
  const { lookupType, lookupBy, lookupValue } = useParams<LookupRouteParams>();
  const [tableData, setTableData] = useState<LookupTableData>(
    lookupType === 'account' ? mockAccountData : mockDivisionData
  );
  const [accountsForComparison, { push: pushAccountsForComparison, clear: clearAccountsForComparison }] = useList<
    Account
  >([]);

  useUpdateEffect(() => {
    // When lookupType changes, switch to the appropriate mock data and clear accountsForComparison
    setTableData(lookupType === 'account' ? mockAccountData : mockDivisionData);
    clearAccountsForComparison();
  }, [lookupType]);

  useUpdateEffect(() => {
    console.log('LookupPage useUpdateEffect: accountsForComparison', accountsForComparison);
    if (accountsForComparison.length > 1) {
      const selectedAccountIds = accountsForComparison.map(account => account.id);
      const path = `/reconcile/accounts/${selectedAccountIds}`;
      history.push(path);
    }
  }, [accountsForComparison]);

  const saveAccountsForComparison = (accountDetails: Account[]) => pushAccountsForComparison(...accountDetails);

  return (
    <div>
      <Box pt={5}>
        <LookupForm lookupType={lookupType} lookupBy={lookupBy} lookupValue={lookupValue} />
      </Box>

      {accountsForComparison.length > 0 && (
        <ComparisonPanel
          useGlobalColumns={useGlobalColumns}
          accountForComparison={accountsForComparison[0]}
          onClearAccountsForComparison={clearAccountsForComparison}
        />
      )}
      {lookupType && (
        <LookupTable
          lookupType={lookupType}
          tableData={tableData}
          saveAccountsForComparison={saveAccountsForComparison}
          accountIdsForComparison={accountsForComparison.map(a => a.id)}
          useGlobalColumns={useGlobalColumns}
        />
      )}
    </div>
  );
};
