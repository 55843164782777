import React, { FC } from 'react';
import { Link } from '@c2fo/react-components';
import { AccountWebsiteLinkProps } from '../../schemas/compare';

export const AccountWebsiteLink: FC<AccountWebsiteLinkProps> = ({ website }) => {
  if (!website) return <>N/A</>;

  const externalUrl = withHttp(website);
  const displayLink = withoutHttp(externalUrl);

  return (
    <Link href={externalUrl} target="_blank" rel="noopener noreferrer">
      {displayLink}
    </Link>
  );
};

const withHttp = (url: string) => (!/^https?:\/\//i.test(url) ? `http://${url}` : url);
const withoutHttp = (url: string) => url.replace(/^(https?:|)\/\//, '');
