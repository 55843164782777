import React, { FC, useEffect, useMemo } from 'react';
import MUIDataTable, { MUIDataTableOptions, MUIDataTableColumn, MUIDataTableMeta } from 'mui-datatables';
import { useLocalStorage, useUpdateEffect } from 'react-use';
import { Box, Button, TextField, Autocomplete } from '@c2fo/react-components';
import { cloneDeep } from 'lodash';

import { SalesforceAccountLink, SalesforceDivisionLink, AnimatedPulseIcon } from '../../components';
import { ExpandableRows } from './ExpandableRows';
import { LookupTableToolbar } from './LookupTableToolbar';
import { iso2Countries, mockDivisionData } from '../../helpers/mockData';
import { formatCurrency } from '../../helpers/currency';
import { Account, LookupTableProps } from '../../schemas/lookup';

const divisionColumns: MUIDataTableColumn[] = [
  {
    name: 'uuid',
    label: 'Division UUID',
    options: {
      display: false,
      filter: false,
    },
  },
  {
    name: 'divisionId',
    label: 'Division ID (Salesforce)',
    options: {
      display: 'excluded',
      filter: false,
    },
  },
  {
    name: 'businessId',
    label: 'EIS Component ID',
    options: {
      display: true,
      filter: false,
    },
  },
  {
    name: 'accountId',
    label: 'Account ID',
    options: {
      display: false,
      filter: false,
    },
  },
  {
    name: 'name',
    label: 'Name',
    options: {
      customBodyRenderLite: (dataIndex: number) => (
        <SalesforceDivisionLink
          divisionId={mockDivisionData[dataIndex].divisionId}
          divisionName={mockDivisionData[dataIndex].name}
        />
      ),
      filter: false,
    },
  },
  {
    name: 'address1',
    label: 'Address',
    options: {
      filter: false,
    },
  },
  {
    name: 'address2',
    label: 'Address 2',
    options: {
      display: false,
      filter: false,
    },
  },
  {
    name: 'city',
    label: 'City',
    options: {
      display: false,
      filter: false,
    },
  },
  {
    name: 'region',
    label: 'Region/State',
    options: {
      display: false,
      filter: false,
    },
  },
  {
    name: 'postalCode',
    label: 'Postal',
    options: {
      display: false,
      filter: false,
    },
  },
  {
    name: 'country',
    label: 'Country',
    options: {
      filter: false,
    },
  },
  {
    name: 'dnbDomain',
    label: 'DNB Domain',
    options: {
      display: false,
      filter: false,
    },
  },
  {
    name: 'userDomain',
    label: 'User Domain',
    options: {
      display: false,
      filter: false,
    },
  },
  {
    name: 'taxId',
    label: 'Tax ID',
    options: {
      display: false,
      filter: false,
    },
  },
  {
    name: 'dunsNumber',
    label: 'DUNS Number',
    options: {
      display: false,
      filter: false,
    },
  },
  {
    name: 'buyerName',
    label: 'Buyer',
    options: {
      display: false,
      sort: true,
      filter: true,
      filterType: 'custom',
      filterOptions: {
        // TODO: Removed for now, due to reference to non-existing buyerNames mock data.
        //       Plan to fix when we revive Lookup Tables in the future.
        // display: (filterList, onChange, index, column) => (
        //   <Autocomplete
        //     options={buyerNames}
        //     getOptionLabel={option => option.label}
        //     onChange={(_, value) => {
        //       // TODO: Could store this value in state, so we can use it in handleFilterSubmit()
        //       //       after the backend is ready, and serverSide:true is set.
        //       //       The backend filtering cares about the buyer's ID, found in `value.id`
        //       onChange([value?.label], index, column);
        //     }}
        //     renderInput={params => <TextField {...params} label="Buyer" />}
        //   />
        // ),
        // logic: (buyerName, filterBuyer) => {
        //   if (filterBuyer.length) return !filterBuyer.includes(buyerName);
        //   return false;
        // },
        // fullWidth: true,
      },
    },
  },
  {
    name: 'participationDate',
    label: 'Participated',
    options: {
      customBodyRenderLite: (dataIndex: number) => (
        <AnimatedPulseIcon participationDate={mockDivisionData[dataIndex].participationDate} />
      ),
      customFilterListOptions: {
        render: v => `${v ? 'Participated' : 'Non-Participated'}`,
      },
      filter: true,
      sort: false,
      display: false,
      filterOptions: {
        renderValue: v => (v ? 'Participated' : 'Non-Participated'),
        fullWidth: true,
      },
    },
  },
];

const accountColumns: MUIDataTableColumn[] = [
  {
    name: 'id',
    label: 'Account ID',
    options: {
      display: 'excluded',
      filter: false,
    },
  },
  {
    name: 'name',
    label: 'Name',
    options: {
      customBodyRender: (value: string, tableMeta: MUIDataTableMeta) => (
        <SalesforceAccountLink accountId={tableMeta.rowData[0]} accountName={value} />
      ),
      filter: false,
    },
  },
  {
    name: 'eligibleAp',
    label: 'Eligible AP',
    options: {
      customBodyRender: (value: number) => formatCurrency(value, { compact: true }),
      filter: false,
      sortDescFirst: true,
    },
  },
  { name: 'businessCount', label: 'Businesses', options: { filter: false } },
  { name: 'divisionCount', label: 'Divisions', options: { filter: false } },
  { name: 'assignedSrm', label: 'Assigned SRM', options: { filter: false } },
  { name: 'team', label: 'Team', options: { filter: false } },
  {
    name: 'phone',
    label: 'Phone',
    options: {
      display: false,
      filter: false,
    },
  },
  {
    name: 'website',
    label: 'Website',
    options: {
      display: false,
      filter: false,
    },
  },
  {
    name: 'country',
    label: 'Country',
    options: {
      display: false,
      filter: false,
    },
  },
  {
    name: 'participated',
    label: 'Participated',
    options: {
      customBodyRender: (value: string | null) => <AnimatedPulseIcon participationDate={value} />,
      customFilterListOptions: {
        render: v => `${v ? 'Participated' : 'Non-Participated'}`,
      },
      filter: true,
      sort: false,
      display: false,
      filterOptions: {
        renderValue: v => (v ? 'Participated' : 'Non-Participated'),
        fullWidth: true,
      },
    },
  },
  {
    name: 'billingCountry',
    label: 'Billing Country',
    options: {
      display: false,
      sort: true,
      filter: true,
      filterType: 'custom',
      filterOptions: {
        display: (filterList, onChange, index, column) => (
          <Autocomplete
            options={iso2Countries}
            getOptionLabel={option => option.name}
            onChange={(_, value) => {
              // TODO: Could store this value in state, so we can use it in handleFilterSubmit()
              // update state with new URL
              //       after the backend is ready, and serverSide:true is set.
              //       The backend filtering cares about the buyer's ID, found in `value.id`
              onChange(value ? [value.name] : [], index, column);
            }}
            renderInput={params => <TextField {...params} label="Billing Country" />}
          />
        ),
        logic: (billingCountry, filterBillingCountry) => {
          if (filterBillingCountry.length) return !filterBillingCountry.includes(billingCountry);
          return false;
        },
        fullWidth: true,
      },
    },
  },
];

export const LookupTable: FC<LookupTableProps> = ({
  lookupType,
  tableData,
  saveAccountsForComparison,
  accountIdsForComparison,
  useGlobalColumns,
}) => {
  const [, setGlobalColumns] = useGlobalColumns();
  const initialColumns = useMemo(() => (lookupType === 'account' ? accountColumns : divisionColumns), [lookupType]);
  const [storedColumns, setStoredColumns] = useLocalStorage<MUIDataTableColumn[]>(
    `${lookupType}-selected-columns`,
    initialColumns,
    {
      raw: false,
      serializer: JSON.stringify,
      deserializer: (value: string) => {
        const storedVersion = JSON.parse(value);
        const combinedColumns = initialColumns.map((item, i) => {
          // If there's an existing record in localStorage for this column's display option,
          // use that value to override the initial column's options.
          if (!storedVersion[i].options.display) return item;
          else return { ...item, options: { ...item.options, display: storedVersion[i].options.display } };
        });
        return combinedColumns;
      },
    }
  );

  useEffect(() => {
    setGlobalColumns(storedColumns);
  }, [storedColumns, setGlobalColumns]);

  const isolateSelectedAccountDetails = (selectedDataIndexes: number[]) => {
    const selectedAccountDetails = selectedDataIndexes.map(index => tableData[index]) as Account[];

    saveAccountsForComparison(selectedAccountDetails);
  };

  useUpdateEffect(() => {
    // When lookupType changes, fetch the appropriate columns from localStorage
    const storedColumnsString = localStorage.getItem(`${lookupType}-selected-columns`);
    if (storedColumnsString) setStoredColumns(JSON.parse(storedColumnsString));
    else setStoredColumns(initialColumns);
  }, [lookupType]);

  const updateStoredColumns = (changedColumn: string, action: string) => {
    // Make a clone of storedColumns to avoid editing state value directly
    const tempColumns = cloneDeep(storedColumns);

    // Find the column that changed in storedColumns, and
    const foundColumn = tempColumns?.find(c => c.name === changedColumn);

    if (foundColumn) {
      // Update the value of display to false (for 'remove') or true (for 'add')
      if (foundColumn.options) foundColumn.options.display = action !== 'remove';
      else {
        foundColumn.options = {
          display: action !== 'remove',
        };
      }

      // Set modified clone of storedColumns
      setStoredColumns(tempColumns);
    }
  };

  const handleFilterSubmit = (applyNewFilters: any) => {
    const filterList = applyNewFilters();
    console.log(filterList);
    // TODO: Update state here, to use as params in API request
  };

  const options: MUIDataTableOptions = {
    filterType: 'dropdown',
    confirmFilters: true,
    // serverSide: true, // While this is set to false (default), it will actually continue to filter client side, despite whatever goes on in handleFilterSubmit()
    customFilterDialogFooter: (_, applyNewFilters) => {
      return (
        <div style={{ marginTop: '40px', width: '475px' }}>
          <Button variant="contained" onClick={() => handleFilterSubmit(applyNewFilters)}>
            Apply Filters
          </Button>
        </div>
      );
    },
    download: false,
    print: false,
    search: false,
    onViewColumnsChange: updateStoredColumns,
    isRowSelectable: (dataIndex, selectedRows) => {
      // If accountIdsForComparison contains this row, disable the checkbox
      if (accountIdsForComparison.includes((tableData[dataIndex] as Account).id)) return false;

      // If no rows are currently selected, or this row is selected, enable the checkbox
      if (!selectedRows || selectedRows.data.find(d => d.dataIndex === dataIndex)) return true;

      // If more than 2 accounts are selected, disable the checkbox
      if (selectedRows.data.length + accountIdsForComparison.length > 1) return false;

      return true;
    },
    selectableRowsHeader: false,
    selectableRows: lookupType === 'account' ? 'multiple' : 'none',
    rowsSelected: [],
    customToolbarSelect: selectedRows => (
      <LookupTableToolbar
        selectedRows={selectedRows}
        onClickCompareSelected={isolateSelectedAccountDetails}
        isReadyForComparison={accountIdsForComparison.length === 1}
      />
    ),
    expandableRows: lookupType === 'account',
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    rowsExpanded: [],
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      return <ExpandableRows colSpan={colSpan} tableData={mockDivisionData} />;
    },
    draggableColumns: { enabled: true },
    textLabels: {
      selectedRows: { text: 'account(s) selected' },
      viewColumns: { title: 'Select Columns' },
      toolbar: { viewColumns: 'Select Columns', filterTable: 'Filters' },
    },
  };

  return (
    <Box p={3}>
      <MUIDataTable
        title={lookupType.charAt(0).toUpperCase() + lookupType.slice(1) + 's'}
        data={tableData}
        columns={storedColumns!}
        options={options}
      />
    </Box>
  );
};
