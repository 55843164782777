import React, { FC } from 'react';
import { Link } from '@c2fo/react-components';

interface SalesforceAccountLinkProps {
  accountId: string;
  accountName: string;
}

export const SalesforceAccountLink: FC<SalesforceAccountLinkProps> = ({ accountId, accountName }) => (
  <Link
    href={`https://c2fo.lightning.force.com/lightning/r/Account/${accountId}/view`}
    target="_blank"
    rel="noopener noreferrer"
  >
    {accountName}
  </Link>
);
