import { Division } from './lookup';

export interface AccountDetails {
  name: string;
  id: string;
  owner: string;
  ownerTeam: string;
  website: string | null;
  businessGroups: BusinessGroup[];
}

export interface BusinessGroup {
  id: string;
  divisions: Division[];
}

export interface ComparePageRouteParams {
  candidateId?: string;
}

export interface DivisionColumn {
  name: string;
  label: string;
  display: boolean;
  classNames?: string[];
}

export interface DivisionTableDroppableProps {
  accountId: string;
  businessId: string;
  divisions: Division[];
  isResolved: boolean;
  selectedDivisionIds: string[];
  draggingDivisionId: string | null;
  selectedColumns: DivisionColumn[];
  multiSelectTo: (divisionId: string, accountId: string, businessId: string) => void;
  toggleSelection: (divisionId: string) => void;
  toggleSelectionInGroup: (divisionId: string, accountId: string, businessId: string) => void;
}

export const isOfType = <T>(varToBeChecked: any, propertyToCheckFor: keyof T): varToBeChecked is T =>
  (varToBeChecked as T)[propertyToCheckFor] !== undefined;

export type PartialDivision = Pick<Division, 'divisionId' | 'name'>;

export interface DivestActionBlob {
  type: string;
  fromAccountId: string;
  fromAccountName: string;
  fromAccountOwner: string;
  toAccountId: string;
  toAccountName: string;
  toAccountOwner: string;
  divisions: PartialDivision[];
}

export interface MergeActionBlob {
  type: string;
  fromAccountId: string;
  fromAccountName: string;
  fromAccountOwner: string;
  toAccountId: string;
  toAccountName: string;
  toAccountOwner: string;
  divisionsMoved: number;
}

export interface DiffResult {
  author: string;
  resolution: string;
  actions: MergeActionBlob[] | DivestActionBlob[];
}

export interface DivisionFeedback {
  divisions: string[];
  accountId: string;
}

export interface MappedDivision {
  divisionId: string;
  name: string;
  businessId: string;
  accountId: string;
}

export enum Resolution {
  Merge = 'merge',
  ReverseMerge = 'reverse_merge',
  DivestFull = 'divest_full',
  DivestPartial = 'divest_partial',
  Divest = 'divest',
  MutualDivest = 'mutual_divest',
  ReverseDivest = 'reverse_divest',
  ReverseDivestFull = 'reverse_divest_full',
  ReverseDivestPartial = 'reverse_divest_partial',
  NewAccountFull = 'new_account_full',
  NewAccountPartial = 'new_account_partial',
  Complex = 'complex',
  Rejected = 'rejected',
  Stale = 'stale',
}

export interface AccountWebsiteLinkProps {
  website: string | null;
}

export const defaultCompareColumns: DivisionColumn[] = [
  {
    name: 'uuid',
    label: 'Division UUID',
    display: false,
    classNames: ['wideColumn'],
  },
  {
    name: 'divisionId',
    label: 'Division ID (Salesforce)',
    display: false,
    classNames: ['wideColumn'],
  },
  {
    name: 'name',
    label: 'Name',
    display: true,
    classNames: ['wideColumn'],
  },
  {
    name: 'address1',
    label: 'Address',
    display: true,
  },
  {
    name: 'address2',
    label: 'Address 2',
    display: false,
  },
  {
    name: 'city',
    label: 'City',
    display: false,
    classNames: ['narrowColumn'],
  },
  {
    name: 'region',
    label: 'Region/State',
    display: false,
  },
  {
    name: 'postalCode',
    label: 'Postal',
    display: false,
  },
  {
    name: 'country',
    label: 'Country',
    display: false,
    classNames: ['countryColumn'],
  },
  {
    name: 'dnbDomain',
    label: 'DNB Domain',
    display: false,
  },
  {
    name: 'userDomain',
    label: 'User Domain',
    display: true,
  },
  {
    name: 'taxId',
    label: 'Tax ID',
    display: true,
    classNames: ['taxIdColumn'],
  },
  {
    name: 'dunsNumber',
    label: 'DUNS Number',
    display: false,
  },
  {
    name: 'buyerName',
    label: 'Buyer',
    display: true,
  },
  {
    name: 'participationDate',
    label: 'Participated',
    display: false,
    classNames: ['alignCenter', 'participatedColumn'],
  },
  {
    name: 'createdDate',
    label: 'Created',
    display: false,
    classNames: ['createdColumn'],
  },
];
