import React, { FC } from 'react';
import { AppBar, Box, Liquidity, Logo, makeStyles, Theme, Toolbar, TypeBase } from '@c2fo/react-components';

import { NavButton } from './NavButton';

export const Nav: FC = () => {
  const classes = useStyles();

  return (
    <AppBar className={classes.appBar} position="sticky">
      <Toolbar variant="dense">
        <Logo className={classes.logo} color="white" height="20px" />
        <TypeBase customColor="white">Enterprise Entity Management System</TypeBase>
        <Box display="flex" ml={4}>
          <NavButton label="Reconciliation Candidates" path="/" exact />
          {/* <NavButton label="Lookup" path="/lookup" /> */}
          <NavButton label="Reconciliation History" path="/history" />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  appBar: {
    backgroundColor: Liquidity.colors.main.secondary,
  },
  logo: {
    marginRight: theme.spacing(1),
  },
}));
