import React, { FC, useState } from 'react';
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import {
  Box,
  Chip,
  Liquidity,
  TypeBase,
  TypeHelper,
  TypeLegalese,
  TextField,
  FormGroup,
  FormLabel,
  CircularProgress,
  C2FOTheme,
  CheckIcon,
  CloseIcon,
} from '@c2fo/react-components';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { capitalize } from 'lodash';

import { HistoryTableToolbar } from './HistoryTableToolbar';
import { SalesforceAccountLink } from '../../components';
import { useRequest } from '../../hooks/';
import { ReconciliationHistoryTableProps } from '../../schemas/history';
import { isOfType, MergeActionBlob } from '../../schemas/compare';
import { formatDate, formatTime } from '../../helpers/dateTime';

const DivestChip = () => <Chip label="Divest" color="secondary" />;
const MergeChip = () => <Chip label="Merge" color="primary" />;

export const ReconciliationHistoryTable: FC<ReconciliationHistoryTableProps> = ({
  caseHistory,
  isLoading,
  query,
  totalCount,
  onChangePage,
  onChangeRowsPerPage,
  onChangeFilter,
  onResetFilters,
  mutateHistoryRows,
}) => {
  const { data: authorList } = useRequest(`/reconciliation-history/authors`);
  const [rowsSelected, setRowsSelected] = useState<number[]>([]);

  const historyColumns: MUIDataTableColumn[] = [
    {
      name: 'created',
      label: 'Date/Time',
      options: {
        filterList: query.minDate || query.maxDate ? [query.minDate, query.maxDate] : [],
        customBodyRenderLite: (dataIndex: number) => {
          const value = new Date(caseHistory[dataIndex].created);
          return (
            <>
              <TypeLegalese customColor={Liquidity.colors.text.standard}>{formatDate(value)}</TypeLegalese>
              <TypeHelper customColor={Liquidity.colors.text.standard}>{formatTime(value)}</TypeHelper>
            </>
          );
        },
        filterType: 'custom',
        customFilterListOptions: {
          render: v => {
            if (v[0] && v[1]) {
              return [`Min Date: ${v[0]}`, `Max Date: ${v[1]}`];
            } else if (v[0]) {
              return `Min Date: ${v[0]}`;
            } else if (v[1]) {
              return `Max Date: ${v[1]}`;
            }
            return [];
          },
          update: (filterList, filterPos, index) => {
            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, '');
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1);
            } else if (filterPos === -1) {
              filterList[index] = [];
            }

            return filterList;
          },
        },
        filterOptions: {
          fullWidth: true,
          display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel>Date Range</FormLabel>
              <FormGroup row>
                <TextField
                  type="date"
                  value={filterList[index][0] || ''}
                  onChange={event => {
                    const newFilterList = [event.target.value, filterList[index][1]];
                    onChange(newFilterList, index, column);
                  }}
                  style={{ width: '47.5%', marginRight: '5%' }}
                />
                <TextField
                  type="date"
                  value={filterList[index][1] || ''}
                  onChange={event => {
                    const newFilterList = [filterList[index][0], event.target.value];
                    onChange(newFilterList, index, column);
                  }}
                  style={{ width: '47.5%' }}
                />
              </FormGroup>
            </div>
          ),
        },
      },
    },
    {
      name: 'author',
      label: 'Author',
      options: {
        filterList: query.author ? [query.author] : [],
        filterType: 'dropdown',
        filterOptions: {
          fullWidth: true,
          names: authorList,
        },
      },
    },
    {
      name: 'fromAccount',
      label: 'From Account',
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex: number) => {
          const value = caseHistory[dataIndex].action;
          return (
            <>
              <SalesforceAccountLink accountId={value.fromAccountId} accountName={value.fromAccountName} />
              <TypeLegalese>{value.fromAccountOwner}</TypeLegalese>
            </>
          );
        },
      },
    },
    {
      name: 'toAccount',
      label: 'To Account',
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex: number) => {
          const value = caseHistory[dataIndex].action;
          return (
            <>
              <SalesforceAccountLink accountId={value.toAccountId} accountName={value.toAccountName} />
              <TypeLegalese>{value.toAccountOwner}</TypeLegalese>
            </>
          );
        },
      },
    },
    {
      name: 'actionType',
      label: 'Action',
      options: {
        filterList: query.actionType ? [capitalize(query.actionType)] : [],
        filterType: 'dropdown',
        filterOptions: {
          names: ['Merge', 'Divest'],
          fullWidth: true,
        },
        setCellProps: () => ({
          align: 'center',
        }),
        setCellHeaderProps: () => ({
          align: 'center',
        }),
        customBodyRenderLite: (dataIndex: number) => {
          const value = caseHistory[dataIndex].actionType;
          return value === 'divest' ? <DivestChip /> : <MergeChip />;
        },
      },
    },
    {
      name: 'numDivisions',
      label: 'Divisions Moved',
      options: {
        filter: false,
        setCellProps: () => ({
          align: 'center',
        }),
        setCellHeaderProps: () => ({
          align: 'center',
        }),
        customBodyRenderLite: (dataIndex: number) => {
          const value = caseHistory[dataIndex].action;
          if (isOfType<MergeActionBlob>(value, 'divisionsMoved')) return value.divisionsMoved;
          return value.divisions.length;
        },
      },
    },
    {
      name: 'downloaded',
      label: 'Downloaded',
      options: {
        filterList: query.downloaded ? [query.downloaded] : [],
        filterOptions: {
          names: ['True', 'False'],
          fullWidth: true,
        },
        customFilterListOptions: {
          render: v => `Downloaded: ${v}`,
        },
        setCellProps: () => ({ align: 'center' }),
        setCellHeaderProps: () => ({ align: 'center' }),
        customBodyRenderLite: (dataIndex: number) =>
          caseHistory[dataIndex].downloaded ? <CheckIcon color="primary" /> : <CloseIcon color="error" />,
      },
    },
  ];

  const options: MUIDataTableOptions = {
    serverSide: true,
    download: false,
    search: false,
    print: false,
    sort: false,
    textLabels: {
      body: { noMatch: isLoading ? 'Loading...' : 'Sorry, no matching records found' },
      selectedRows: { text: 'case(s) selected' },
    },
    rowsSelected,
    onRowSelectionChange: (_, allRowsSelected) => {
      setRowsSelected(allRowsSelected.map(row => row.dataIndex));
    },
    setTableProps: () => ({ size: 'small' }),
    customToolbarSelect: selectedRows => (
      <HistoryTableToolbar
        data={caseHistory}
        selectedRows={selectedRows}
        mutateHistoryRows={mutateHistoryRows}
        resetRowsSelected={() => setRowsSelected([])}
      />
    ),
    page: query.page,
    onChangePage: (currentPage: number) => {
      setRowsSelected([]);
      onChangePage(currentPage);
    },
    rowsPerPageOptions: [10, 25, 100, 250, 500, 1000],
    rowsPerPage: query.rowsPerPage,
    onChangeRowsPerPage: (numberOfRows: number) => {
      setRowsSelected([]);
      onChangeRowsPerPage(numberOfRows);
    },
    count: totalCount,
    onFilterChange: (column, filterList, type) => {
      setRowsSelected([]);
      if (type === 'reset') onResetFilters();
      else if (column) onChangeFilter(column as string, filterList);
    },
  };

  return (
    <Box p={3}>
      <MuiThemeProvider theme={tableTheme}>
        <MUIDataTable
          title={
            <TypeBase component="h6" style={{ fontSize: '1.25rem', fontWeight: 500 }}>
              Reconciliation History
              {isLoading && (
                <CircularProgress
                  size="20px"
                  color="secondary"
                  style={{ marginLeft: '16px', position: 'relative', top: '4px' }}
                />
              )}
            </TypeBase>
          }
          data={caseHistory}
          columns={historyColumns}
          options={options}
        />
      </MuiThemeProvider>
    </Box>
  );
};

const tableTheme = createMuiTheme(C2FOTheme, {
  overrides: {
    MUIDataTableFilter: {
      root: { minWidth: '620px' },
    },
    MUIDataTableBodyCell: {
      root: {
        '&> svg': {
          verticalAlign: 'middle',
        },
      },
    },
  },
});
