import useSWR from 'swr';

import { config } from '../config';
import { getToken, redirectToAdminLogin } from '../helpers/auth';

const fetcher = async (path: string) => {
  const response = await fetch(path, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });

  if (response.ok) return response.json();
  else if (response.status === 401) redirectToAdminLogin();
  else {
    console.error(`Received response with HTTP status code ${response.status}`);
    const errorBody = await response.json();
    console.error(`Error message: ${errorBody.error}`);
    throw new Error(errorBody.error);
  }
};

interface Map {
  [key: string]: string | number | undefined;
}

export const useRequest = (path: string, queryParams?: Map) => {
  if (!path) throw new Error('Path is required');
  path = config.EEMS_API_URL + path;

  // If query parameters are provided, append them to the end of the URL
  // Ignores undefined values and empty strings
  if (queryParams) {
    const url = new URL(path);
    Object.keys(queryParams).forEach(
      key =>
        queryParams[key] !== undefined &&
        queryParams[key] !== '' &&
        url.searchParams.append(key, queryParams[key] as string)
    );
    path = url.toString();
  }

  return useSWR(path, fetcher, {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  });
};
