import React, { useMemo, useCallback, FC, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { parse } from 'papaparse';
import { isEqual } from 'lodash';
import { AlertBanner, Box, Button, Liquidity, TypeBase, TypeHelper, TypeLegalese } from '@c2fo/react-components';
import { DivestSuccessLog } from '../../schemas/history';

const baseStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '40px',
  borderRadius: 2,
  borderColor: Liquidity.colors.greys.light,
  borderStyle: 'dashed',
  backgroundColor: Liquidity.colors.greys.background,
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const acceptStyle = {
  borderColor: Liquidity.colors.main.primary,
};

const rejectStyle = {
  borderColor: Liquidity.colors.feedback.error,
};

const expectedHeaders = ['ID', 'RECORDTYPEID', 'STATUS', 'SUBJECT', 'ACCOUNTID', 'DIVEST_TO_ACCOUNT__C', 'HISTORYID'];

interface UploadDropAreaProps {
  onSuccessfulUpload(successLogs: DivestSuccessLog[]): void;
}

export const UploadDropArea: FC<UploadDropAreaProps> = ({ onSuccessfulUpload }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [file, setFile] = useState<File>();

  const onDropAccepted = useCallback(
    acceptedFiles => {
      parse(acceptedFiles[0], {
        skipEmptyLines: 'greedy',
        header: true,
        dynamicTyping: true,
        complete: function (parseResults) {
          const incomingHeaders = Object.keys(parseResults.data[0] as DivestSuccessLog);

          if (!isEqual(incomingHeaders, expectedHeaders)) {
            setErrorMessage('This file does not contain the expected column headers.');
          } else {
            setSuccessMessage('File successfully processed! Click the Download Final Divest CSV button below.');
            onSuccessfulUpload(parseResults.data as DivestSuccessLog[]);
          }
        },
      });
    },
    [onSuccessfulUpload]
  );

  const { acceptedFiles, getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    // accept: 'text/csv',
    maxFiles: 1,
    onDropAccepted,
  });

  useEffect(() => {
    if (acceptedFiles[0]) setFile(acceptedFiles[0]);
  }, [acceptedFiles]);

  const style: {} = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragReject, isDragAccept]
  );

  const onClickReturnButton = () => {
    setSuccessMessage('');
    setErrorMessage('');
    setFile(undefined);
    onSuccessfulUpload([]);
  };

  return (
    <section>
      {!successMessage ? (
        <>
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <TypeHelper>Drag 'n' drop the success log here, or click to select one from the file picker</TypeHelper>
          </div>
          {file && (
            <Box mt={2}>
              <TypeBase isEmphasis>File:</TypeBase>
              <TypeBase spacingBottom={1}>
                {file.name} - {file.size} bytes
              </TypeBase>
              {errorMessage && <AlertBanner severity="error">{errorMessage}</AlertBanner>}
            </Box>
          )}
        </>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center">
          <AlertBanner severity="success">{successMessage}</AlertBanner>
          <Box mt={3}>
            <TypeLegalese align="center">Uploaded the wrong success log?</TypeLegalese>
            <Button size="small" onClick={onClickReturnButton}>
              Return to File Selection
            </Button>
          </Box>
        </Box>
      )}
    </section>
  );
};
