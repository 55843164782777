import React, { FC } from 'react';
import { Box } from '@c2fo/react-components';

import { DivisionLookup } from './DivisionLookup';
import { AccountLookup } from './AccountLookup';
import { LookupFormProps } from '../../schemas/lookup';

export const LookupForm: FC<LookupFormProps> = ({ lookupType, lookupBy, lookupValue }) => {
  return (
    <Box display="flex" justifyContent="space-evenly">
      <DivisionLookup activeSearchModule={lookupType === 'division'} lookupBy={lookupBy} lookupValue={lookupValue} />
      <AccountLookup activeSearchModule={lookupType === 'account'} lookupBy={lookupBy} lookupValue={lookupValue} />
    </Box>
  );
};
