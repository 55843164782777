import React, { ReactNode } from 'react';
import { Route, Switch } from 'react-router-dom';

import { ReconciliationCandidatesPage, LookupPage, ComparePage, ReconciliationHistoryPage } from './pages';
import { isAuthenticated, redirectToLocalhost, redirectToAdminLogin } from './helpers/auth';

const Router: React.FC = () => (
  <Switch>
    <Route
      path="/localhost"
      component={() => {
        redirectToLocalhost();
        return null;
      }}
    />
    <AuthenticatedRouteWrapper isAuthenticated={isAuthenticated}>
      <Route exact path="/">
        <ReconciliationCandidatesPage />
      </Route>
      <Route exact path="/lookup">
        <LookupPage />
      </Route>
      <Route path="/lookup/:lookupType/:lookupBy/:lookupValue">
        <LookupPage />
      </Route>
      <Route path="/reconcile/candidate/:candidateId">
        <ComparePage />
      </Route>
      <Route path="/reconcile/accounts/:accountIds">
        <ComparePage />
      </Route>
      <Route exact path="/history">
        <ReconciliationHistoryPage />
      </Route>
    </AuthenticatedRouteWrapper>
  </Switch>
);

export default Router;

interface AuthenticatedRouteWrapperProps {
  isAuthenticated: () => boolean;
  children: ReactNode | ReactNode[];
}

const AuthenticatedRouteWrapper = ({ isAuthenticated, children }: AuthenticatedRouteWrapperProps) => {
  if (isAuthenticated()) return <>{children}</>;
  else {
    redirectToAdminLogin();
    return null;
  }
};
