import { differenceWith, sortBy, uniq } from 'lodash';
import {
  AccountDetails,
  DiffResult,
  DivestActionBlob,
  MappedDivision,
  MergeActionBlob,
  PartialDivision,
  Resolution,
} from '../schemas/compare';
import { getUserFullName } from './auth';

export const generateCaseResult = (
  crossoverBusinessId: string,
  initialAccountState: AccountDetails[],
  finalAccountState: AccountDetails[]
) => {
  const initialCandidateAccount = initialAccountState[0];
  const initialTargetAccount = initialAccountState[1];
  const finalCandidateAccount = finalAccountState[0];
  const finalTargetAccount = finalAccountState[1];

  const finalCandidateDivisions = getDivisionFlatMap(finalCandidateAccount);
  const finalTargetDivisions = getDivisionFlatMap(finalTargetAccount);
  let finalDivisions = [...finalCandidateDivisions, ...finalTargetDivisions];
  finalDivisions = sortBy(finalDivisions, ['divisionId']);
  const initialCandidateDivisions = getDivisionFlatMap(initialCandidateAccount);
  const initialTargetDivisions = getDivisionFlatMap(initialTargetAccount);
  let initialDivisions = [...initialCandidateDivisions, ...initialTargetDivisions];
  initialDivisions = sortBy(initialDivisions, ['divisionId']);

  // If either account is empty of divisions, it must be a merge or reverse-merge
  if (finalCandidateDivisions.length === 0) {
    // merge
    const actionBlob = generateMergeBlob(
      Resolution.Merge,
      finalCandidateAccount,
      finalTargetAccount,
      initialCandidateDivisions.length
    );
    return setDiffResult(Resolution.Merge, [actionBlob!]);
  } else if (finalTargetDivisions.length === 0) {
    // reverse-merge
    const actionBlob = generateMergeBlob(
      Resolution.ReverseMerge,
      finalCandidateAccount,
      finalTargetAccount,
      initialTargetDivisions.length
    );
    return setDiffResult(Resolution.ReverseMerge, [actionBlob!]);
  }

  // Should provide list of divisions moved between accounts (with accountId & businessId based on source)
  const totalDiff = differenceWith(
    initialDivisions,
    finalDivisions,
    (a, b) => a.divisionId === b.divisionId && a.accountId === b.accountId
  );

  // If no divisions moved, resolution should be Rejected
  if (!totalDiff.length) {
    return {
      resolution: Resolution.Rejected,
      author: getUserFullName(),
      actions: [],
    };
  }

  // Should provide list of divisions moved between accounts (with accountId & businessId based on destination)
  const reverseTotalDiff = differenceWith(
    finalDivisions,
    initialDivisions,
    (a, b) => a.divisionId === b.divisionId && a.accountId === b.accountId
  );

  // If more than 1 accountId is found amongst the moved divisions in reverseTotalDiff,
  // then this must be a mutual-divest
  if (uniq(reverseTotalDiff.map(d => d.accountId)).length > 1) {
    // First, get the forward divests from Candidate -> Target
    const forwardDivestedDivisions = reverseTotalDiff
      .filter(d => d.accountId === finalTargetAccount.id)
      .map(d => ({ divisionId: d.divisionId, name: d.name }));

    const forwardDivestBlob = generateDivestBlob(
      Resolution.Divest,
      finalCandidateAccount,
      finalTargetAccount,
      forwardDivestedDivisions
    );

    // Next, get the reverse divests from Target -> Candidate
    const reverseDivestedDivisions = reverseTotalDiff
      .filter(d => d.accountId === finalCandidateAccount.id)
      .map(d => ({ divisionId: d.divisionId, name: d.name }));

    const reverseDivestBlob = generateDivestBlob(
      Resolution.ReverseDivest,
      finalCandidateAccount,
      finalTargetAccount,
      reverseDivestedDivisions
    );

    return setDiffResult(Resolution.MutualDivest, [forwardDivestBlob!, reverseDivestBlob!]);
  }

  // Otherwise, the resolution must be some form of divest
  const divestResolution = determineDivestResolution(
    crossoverBusinessId,
    totalDiff,
    reverseTotalDiff,
    initialCandidateDivisions,
    finalCandidateDivisions,
    initialTargetDivisions,
    finalTargetDivisions
  );

  const divestBlob = generateDivestBlob(
    divestResolution,
    finalCandidateAccount,
    finalTargetAccount,
    reverseTotalDiff.map(d => ({ divisionId: d.divisionId, name: d.name }))
  );

  return setDiffResult(divestResolution, [divestBlob!]);
};

const determineDivestResolution = (
  crossoverBusinessId: string,
  totalDiff: MappedDivision[],
  reverseTotalDiff: MappedDivision[],
  initialCandidateDivisions: MappedDivision[],
  finalCandidateDivisions: MappedDivision[],
  initialTargetDivisions: MappedDivision[],
  finalTargetDivisions: MappedDivision[]
): Resolution => {
  const nonCrossoverDivisionCount = reverseTotalDiff.filter(
    (div, index) => div.businessId !== crossoverBusinessId || totalDiff[index].businessId !== crossoverBusinessId
  ).length;
  if (nonCrossoverDivisionCount === 0) {
    // If all involved divisions were in the crossover business, then proceed to determining whether resolution is divest_full/divest_partial/reverse_divest_full/reverse_divest_partial
    const initialCandidateCrossoverCount = initialCandidateDivisions.filter(d => d.businessId === crossoverBusinessId)
      .length;
    const finalCandidateCrossoverCount = finalCandidateDivisions.filter(d => d.businessId === crossoverBusinessId)
      .length;
    const initialTargetCrossoverCount = initialTargetDivisions.filter(d => d.businessId === crossoverBusinessId).length;
    const finalTargetCrossoverCount = finalTargetDivisions.filter(d => d.businessId === crossoverBusinessId).length;

    if (
      finalCandidateCrossoverCount === 0 &&
      finalTargetDivisions.length === initialTargetDivisions.length + initialCandidateCrossoverCount
    )
      return Resolution.DivestFull;
    if (
      finalTargetCrossoverCount === 0 &&
      finalCandidateDivisions.length === initialCandidateDivisions.length + initialTargetCrossoverCount
    )
      return Resolution.ReverseDivestFull;
    if (initialCandidateDivisions.length > finalCandidateDivisions.length) return Resolution.DivestPartial;
    if (initialTargetDivisions.length > finalTargetDivisions.length) return Resolution.ReverseDivestPartial;
  } else {
    // If any divisions involved in the action blob weren't in the crossover, then proceed with resolution as divest/reverse-divest
    if (initialCandidateDivisions.length > finalCandidateDivisions.length) return Resolution.Divest;
    if (initialTargetDivisions.length > finalTargetDivisions.length) return Resolution.ReverseDivest;
  }

  // If all else fails, must be a complex case!
  return Resolution.Complex;
};

function getDivisionFlatMap(account: AccountDetails) {
  return account.businessGroups.flatMap(businessGroup =>
    businessGroup.divisions.map(division => ({
      divisionId: division.divisionId,
      name: division.name,
      businessId: businessGroup.id,
      accountId: account.id,
    }))
  );
}

// Generates a divest blob based on resolution type, digs into helper setters for more detailed blobType requirements
function generateDivestBlob(
  resolutionResult: Resolution,
  candidateAccount: AccountDetails,
  targetAccount: AccountDetails,
  divisions: PartialDivision[]
) {
  if ([Resolution.Divest, Resolution.DivestFull, Resolution.DivestPartial].includes(resolutionResult)) {
    return createDivestBlob(candidateAccount, targetAccount, divisions);
  }
  if (
    [Resolution.ReverseDivest, Resolution.ReverseDivestFull, Resolution.ReverseDivestPartial].includes(resolutionResult)
  ) {
    return createDivestBlob(targetAccount, candidateAccount, divisions);
  }
}

const createDivestBlob = (
  fromAccount: AccountDetails,
  toAccount: AccountDetails,
  divisions: PartialDivision[]
): DivestActionBlob => ({
  type: 'divest',
  fromAccountId: fromAccount.id,
  fromAccountName: fromAccount.name,
  fromAccountOwner: fromAccount.owner,
  toAccountId: toAccount.id,
  toAccountName: toAccount.name,
  toAccountOwner: toAccount.owner,
  divisions,
});

// Generates a merge blob based on resolution type, digs into helper/setters for more detailed blobType requirements
function generateMergeBlob(
  resolutionResult: Resolution,
  candidateAccount: AccountDetails,
  targetAccount: AccountDetails,
  divisionsMoved: number
) {
  if (resolutionResult === Resolution.Merge) {
    return createMergeBlob(candidateAccount, targetAccount, divisionsMoved);
  }
  if (resolutionResult === Resolution.ReverseMerge) {
    return createMergeBlob(targetAccount, candidateAccount, divisionsMoved);
  }
}

const createMergeBlob = (
  fromAccount: AccountDetails,
  toAccount: AccountDetails,
  divisionsMoved: number
): MergeActionBlob => ({
  type: 'merge',
  fromAccountId: fromAccount.id,
  fromAccountName: fromAccount.name,
  fromAccountOwner: fromAccount.owner,
  toAccountId: toAccount.id,
  toAccountName: toAccount.name,
  toAccountOwner: toAccount.owner,
  divisionsMoved,
});

// helper setter for complete diff result for /POST send
function setDiffResult(resolution: string, actions: MergeActionBlob[] | DivestActionBlob[]): DiffResult {
  return {
    author: getUserFullName(),
    resolution,
    actions,
  };
}
