import React, { FC } from 'react';
import { Button } from '@c2fo/react-components';
import { Link } from 'react-router-dom';

interface LinkButtonProps {
  size?: 'small' | 'medium' | 'large';
  to: string;
  children: string;
}

export const LinkButton: FC<LinkButtonProps> = ({ size, to, children }) => (
  <Button size={size} component={Link} to={to}>
    {children}
  </Button>
);
