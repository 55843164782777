import React, { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  TypeSubsectionHeader,
  makeStyles,
  Select,
  Theme,
  SearchBar,
  Box,
  TypeHelper,
  Paper,
} from '@c2fo/react-components';
import * as Yup from 'yup';
import { LookupFormFieldProps } from '../../schemas/lookup';

const divisionLookupSchema = Yup.object({
  dropSelectValue: Yup.string().required(),
  textInputValue: Yup.string()
    .required()
    .when('dropSelectValue', {
      is: 'uuid',
      then: Yup.string().matches(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i),
      otherwise: Yup.string().min(1),
    }),
});

export const DivisionLookup: FC<LookupFormFieldProps> = ({ lookupBy, lookupValue, activeSearchModule }) => {
  const classes = useStyles();
  const history = useHistory();

  const [dropSelectValue, setDropSelectValue] = useState<string>((activeSearchModule && lookupBy) || 'name');
  const [textInputValue, setTextInputValue] = useState<string>((activeSearchModule && lookupValue) || '');
  const [formError, setFormError] = useState<string>('');

  const handleDivisionSearch = async () => {
    setFormError('');
    const formValid = await divisionLookupSchema.isValid({
      dropSelectValue,
      textInputValue,
    });

    if (formValid) {
      const path = `/lookup/division/${dropSelectValue}/${textInputValue}`;
      history.push(path);
    } else {
      if (dropSelectValue === 'uuid') setFormError('Please enter a valid division UUID');
      if (dropSelectValue === 'name') setFormError('Please enter a search query');
    }
  };

  useEffect(() => {
    // When the active search module changes, reset the form
    if (!activeSearchModule) {
      setFormError('');
      setTextInputValue('');
    }
  }, [activeSearchModule, lookupValue]);

  return (
    <Paper elevation={4}>
      <Box p={2}>
        <TypeSubsectionHeader spacingBottom={1}>Division Lookup</TypeSubsectionHeader>
        <Box display="flex">
          <Select
            className={classes.select}
            onChange={event => setDropSelectValue(event.target.value as string)}
            options={[
              {
                label: 'Name',
                value: 'name',
              },
              {
                label: 'UUID',
                value: 'uuid',
              },
            ]}
            value={dropSelectValue}
          />

          <SearchBar
            onChange={setTextInputValue}
            onClear={() => setTextInputValue('')}
            onSubmit={handleDivisionSearch}
            value={textInputValue}
            showCloseIcon={true}
          />
        </Box>
        <span className={classes.errorMessage}>{formError ?? <TypeHelper isError>{formError}</TypeHelper>}</span>
      </Box>
    </Paper>
  );
};

const useStyles = makeStyles<Theme>(() => ({
  errorMessage: {
    color: 'red',
  },
  select: {
    minWidth: 90,
  },
}));
