import React, { FC } from 'react';
import { Box } from '@c2fo/react-components';
import { useQueryParams } from 'use-query-params';
import { MUIDataTableState } from 'mui-datatables';

import { ReconciliationHistoryTable } from './ReconciliationHistoryTable';
import { useRequest } from '../../hooks/';
import { queryConfig, CaseHistory } from '../../schemas/history';
import { cloneDeep } from 'lodash';

export const ReconciliationHistoryPage: FC = () => {
  const [query, setQuery] = useQueryParams(queryConfig);

  const { data, error, mutate } = useRequest(`/reconciliation-history`, {
    page: query.page,
    rowsPerPage: query.rowsPerPage,
    sortColumn: 'created',
    sortDirection: 'desc',
    author: query.author,
    actionType: query.actionType,
    minDate: query.minDate,
    maxDate: query.maxDate,
    downloaded: query.downloaded,
  });

  const mutateHistoryRows = (newData: CaseHistory[]) => {
    const deep = cloneDeep(data);
    deep.rows = newData;
    mutate(deep, false);
  };

  const onChangeFilter = (filterName: string, filterList: MUIDataTableState['filterList']) => {
    switch (filterName) {
      case 'created':
        setQuery({ minDate: filterList[0][0], maxDate: filterList[0][1] });
        break;
      case 'author':
        setQuery({ author: filterList[1][0] });
        break;
      case 'actionType':
        setQuery({ actionType: filterList[4][0]?.toLowerCase() });
        break;
      case 'downloaded':
        setQuery({ downloaded: filterList[6][0] });
        break;
    }
    setQuery({ page: 0 });
  };

  if (error) return <h1>Something went wrong!</h1>;

  return (
    <Box pt={3} justifyContent="space-evenly">
      <ReconciliationHistoryTable
        caseHistory={data?.rows}
        isLoading={!data}
        query={query}
        totalCount={data?.totalCount}
        onChangePage={page => setQuery({ page })}
        onChangeRowsPerPage={rowsPerPage => setQuery({ rowsPerPage, page: 0 })}
        onChangeFilter={onChangeFilter}
        onResetFilters={() => setQuery({ page: 0, rowsPerPage: query.rowsPerPage }, 'push')}
        mutateHistoryRows={mutateHistoryRows}
      />
    </Box>
  );
};
