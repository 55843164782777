import React, { FC } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TypeHelper } from '@c2fo/react-components';
import { Undo } from '@material-ui/icons';
import { useToggle } from 'react-use';

interface UndoConfirmationDialogProps {
  onConfirm(): void;
}

export const UndoConfirmationDialog: FC<UndoConfirmationDialogProps> = ({ onConfirm }) => {
  const [open, toggleOpen] = useToggle(false);

  const handleConfirm = () => {
    onConfirm();
    toggleOpen();
  };

  return (
    <Box display="inline" mr="12px">
      <Button color="secondary" variant="outlined" onClick={toggleOpen} startIcon={<Undo />}>
        Undo
      </Button>
      <Dialog open={open} onClose={() => toggleOpen(false)} maxWidth="md">
        <DialogTitle>Undo Reconciliation?</DialogTitle>
        <DialogContent>
          <TypeHelper spacingBottom={2}>
            The selected row(s) will be permanently removed from the Reconciliation History Table.
          </TypeHelper>
          <TypeHelper spacingBottom={2}>
            This action will not affect Salesforce if the associated case was already submitted there.
          </TypeHelper>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleOpen} color="secondary" variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
