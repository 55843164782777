import React, { FC } from 'react';
import { Box } from '@c2fo/react-components';
import { useQueryParams } from 'use-query-params';
import { MUIDataTableState } from 'mui-datatables';

import { ReconciliationCandidatesTable } from './ReconciliationCandidatesTable';
import { useRequest } from '../../hooks/';
import { queryConfig } from '../../schemas/reconciliationCandidates';

export const ReconciliationCandidatesPage: FC = () => {
  const [query, setQuery] = useQueryParams(queryConfig);

  const { data, error } = useRequest('/reconciliation-candidates', {
    page: query.page,
    rowsPerPage: query.rowsPerPage,
    sortColumn: query.sortColumn,
    sortDirection: query.sortDirection,
    eisSuggestion: query.eisSuggestion,
    buyerId: query.buyerId,
    supportOwner: query.supportOwner,
    accountId: query.accountId,
  });

  const onChangeFilter = (filterName: string, filterList: MUIDataTableState['filterList']) => {
    switch (filterName) {
      case 'buyer':
        setQuery({ buyerId: filterList[10][0] });
        break;
      case 'eisSuggestion':
        setQuery({ eisSuggestion: filterList[9][0] });
        break;
      case 'supportOwner':
        setQuery({ supportOwner: filterList[12][0] });
        break;
      case 'accountId':
        setQuery({ accountId: filterList[11][0] });
        break;
    }
    setQuery({ page: 0 });
  };

  const onColumnSortChange = (columnName: string) => {
    if (columnName === query.sortColumn) {
      if (query.sortDirection === 'desc') setQuery({ sortDirection: 'asc' });
      else setQuery({ sortDirection: 'desc' });
    } else {
      setQuery({ sortColumn: columnName, sortDirection: 'desc' });
    }
    setQuery({ page: 0 });
  };

  if (error) return <h1>Something went wrong!</h1>;

  return (
    <Box display="flex" flexDirection="column" p={3}>
      <ReconciliationCandidatesTable
        candidates={data?.rows}
        isLoading={!data}
        query={query}
        onColumnSortChange={onColumnSortChange}
        onChangePage={page => setQuery({ page })}
        onChangeRowsPerPage={rowsPerPage => setQuery({ rowsPerPage, page: 0 })}
        totalCount={data?.totalCount}
        onResetFilters={() => setQuery({ page: 0, rowsPerPage: query.rowsPerPage }, 'push')}
        onChangeFilter={onChangeFilter}
      />
    </Box>
  );
};
