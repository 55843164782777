import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Liquidity, makeStyles, Theme } from '@c2fo/react-components';

interface NavButtonProps {
  label: string;
  path: string;
  exact?: boolean;
}

export const NavButton: FC<NavButtonProps> = ({ label, path, exact }) => {
  const classes = useStyles();
  return (
    <Box display="flex" height="48px">
      <NavLink
        to={path}
        exact={exact}
        className={classes.navLink}
        activeStyle={{
          backgroundColor: Liquidity.colors.main.midBlue,
          color: 'white',
        }}
      >
        {label}
      </NavLink>
    </Box>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  navLink: {
    color: 'rgba(255, 255, 255, 0.9)',
    textDecoration: 'none',
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '48px',
    padding: theme.spacing(0, 2),
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));
