import { MUIDataTableState } from 'mui-datatables';
import { NumberParam, StringParam, withDefault, DecodedValueMap } from 'use-query-params';

export interface Candidate {
  id: number;
  accountBId: string;
  accountBName: string;
  accountBSize: number;
  accountBCreated: string;
  accountBOwner: string;
  accountBCrossover: number;
  accountBCrossoverAp: number;
  accountAId: string;
  accountAName: string;
  accountASize: number;
  accountACreated: string;
  accountAOwner: string;
  accountACrossover: number;
  accountACrossoverAp: number;
}

export interface ReconciliationCandidatesTableProps {
  candidates: Candidate[];
  isLoading: boolean;
  onColumnSortChange: (columnName: string) => void;
  onChangePage: (newPage: number) => void;
  onChangeRowsPerPage: (newRowsPerPage: number) => void;
  totalCount: number;
  query: DecodedValueMap<typeof queryConfig>;
  onResetFilters: () => void;
  onChangeFilter: (filterName: string, filterList: MUIDataTableState['filterList']) => void;
}

export const queryConfig = {
  sortColumn: withDefault(StringParam, 'accountBCrossoverAp'),
  sortDirection: withDefault(StringParam, 'desc'),
  page: withDefault(NumberParam, 0),
  rowsPerPage: withDefault(NumberParam, 10),
  eisSuggestion: withDefault(StringParam, ''),
  buyerId: withDefault(StringParam, ''),
  supportOwner: withDefault(StringParam, ''),
  accountId: withDefault(StringParam, ''),
};

export interface BuyerListItem {
  label: string;
  value: string;
}
