import React, { FC } from 'react';
import { Liquidity, makeStyles, Theme, Tooltip } from '@c2fo/react-components';

interface AnimatedPulseIconProps {
  participationDate: string | null;
}

export const AnimatedPulseIcon: FC<AnimatedPulseIconProps> = ({ participationDate }) => {
  const classes = useStyles();
  if (participationDate) {
    return (
      <Tooltip arrow title={participationDate}>
        <span className={classes.enabledPulseIcon} />
      </Tooltip>
    );
  }
  return <span className={classes.disabledPulseIcon} />;
};

const useStyles = makeStyles<Theme>(theme => ({
  disabledPulseIcon: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    background: Liquidity.colors.greys.light,
    display: 'inline-block',
    borderRadius: '50%',
  },
  enabledPulseIcon: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    background: Liquidity.colors.main.primary, // #06A946
    animation: '$pulse 1.5s infinite',
    display: 'inline-block',
    borderRadius: '50%',
  },
  '@keyframes pulse': {
    '0%': {
      boxShadow: `0 0 0 0 ${Liquidity.colors.main.primary}60`, // equivalent of #06A94660 == #06A946 but at 60% opacity
    },
    '70%': {
      boxShadow: `0 0 0 10px ${Liquidity.colors.main.primary}00`, // equivalent of #06A94600 == #06A946 but at 0% opacity
    },
    '100%': {
      boxShadow: `0 0 0 0 ${Liquidity.colors.main.primary}00`, // equivalent of #06A94600 == #06A946 but at 0% opacity
    },
  },
}));
