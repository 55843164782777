import React, { FC, CSSProperties, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import confetti from 'canvas-confetti';
import toMaterialStyle from 'material-color-hash';
import { cloneDeep, remove } from 'lodash';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggingStyle,
  NotDraggingStyle,
  DragStart,
} from 'react-beautiful-dnd';
import {
  Button,
  Box,
  Liquidity,
  TypeSubsectionHeader,
  TypeHelper,
  TypeWidgetHeader,
  AddIcon,
  ArrowRightIcon,
  ArrowLeftIcon,
  makeStyles,
  Theme,
  TypeBase,
  Modal,
  Checkbox,
  FormControlLabel,
  Alert,
  CircularProgress,
} from '@c2fo/react-components';
import { Undo, Redo, Restore, Stars } from '@material-ui/icons';
import { useStateWithHistory, useUpdateEffect, useLocalStorage, useToggle, useEvent } from 'react-use';
import useKeyboardJs from 'react-use/lib/useKeyboardJs';

import { AccountWebsiteLink } from './AccountWebsiteLink';
import { DivisionTableDroppable } from './DivisionTableDroppable';
import { getToken } from '../../helpers/auth';
import { generateCaseResult } from '../../helpers/stateDiffGenerator';
import { generateDivisionFeedback } from '../../helpers/divisionFeedbackGenerator';
import { formatDate, formatTime } from '../../helpers/dateTime';
import { useRequest } from '../../hooks';
import { config } from '../../config';
import { SalesforceAccountLink } from '../../components';
import {
  AccountDetails,
  ComparePageRouteParams,
  defaultCompareColumns,
  DivisionColumn,
  DivisionFeedback,
  DiffResult,
} from '../../schemas/compare';

function confettiGun() {
  confetti({
    particleCount: 150,
    angle: 60,
    spread: 85,
    origin: { x: 0 },
    colors: [Liquidity.colors.main.primary, Liquidity.colors.main.secondary],
  });
  confetti({
    particleCount: 150,
    angle: 120,
    spread: 75,
    origin: { x: 1 },
    colors: [Liquidity.colors.main.primary, Liquidity.colors.main.secondary],
  });
}

// a little function to help us with reordering the result
const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

// helper function to reorder selected division ids,
// so the dragging division can be positioned first (when multiple divisions are moved at the same time)
const reorderSelectedDivisionIds = (selectedDivisionIds: string[], dragged: string | null) => {
  if (selectedDivisionIds.length < 1) return [];
  const orderedSelectedDivisionIds = [...selectedDivisionIds];
  orderedSelectedDivisionIds.sort((a, b) => {
    if (a === dragged) return -1;
    if (b === dragged) return 1;
    return 0;
  });

  return orderedSelectedDivisionIds;
};

// function to help with reordering multi-selected divisions
const reorderMultiDrag = (list: any[], selectedDivisionIds: string[], dragged: string | null, endIndex: number) => {
  const orderedSelectedDivisionIds = reorderSelectedDivisionIds(selectedDivisionIds, dragged);

  const result = Array.from(list);
  const removed = remove(result, division => selectedDivisionIds.includes(division.uuid));
  removed.sort((a, b) => {
    return orderedSelectedDivisionIds.indexOf(a.uuid) - orderedSelectedDivisionIds.indexOf(b.uuid);
  });

  result.splice(endIndex, 0, ...removed);

  return result;
};

const getItemStyle = (
  itemId: string,
  isDragging: boolean,
  draggableStyle?: DraggingStyle | NotDraggingStyle
): CSSProperties => {
  const materialColors100 = toMaterialStyle(itemId, 100);
  const materialColors200 = toMaterialStyle(itemId, 200);

  return {
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    border: '2px solid',
    borderColor: materialColors200.backgroundColor,
    borderRadius: 5,
    overflow: 'hidden',
    color: materialColors100.color,
    boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.3)',
    marginBottom: 10,
    // change background colour to striped if dragging
    background: isDragging
      ? `repeating-linear-gradient(
      45deg,
      ${materialColors100.backgroundColor},
      ${materialColors100.backgroundColor} 3px,
      ${materialColors100.backgroundColor}80 3px,
      ${materialColors100.backgroundColor}80 6px
    )`
      : materialColors100.backgroundColor,
    // styles we need to apply on draggables
    ...draggableStyle,
  };
};

const getListStyle = (isDraggingOver: boolean): CSSProperties => ({
  background: isDraggingOver ? 'lightblue' : '#f5f5f5',
  padding: 10,
  paddingBottom: 0,
  overflowY: 'auto',
  flexGrow: 1,
});

export const ComparePage: FC = () => {
  const { candidateId } = useParams<ComparePageRouteParams>();
  const { data, error, mutate } = useRequest(`/reconciliation-candidates/${candidateId}`);
  const [accounts, setAccounts, accountStateHistory] = useStateWithHistory<AccountDetails[], AccountDetails[]>(
    [],
    1000
  );
  const [selectedDivisionIds, setSelectedDivisionIds] = useState<string[]>([]);
  const [draggingDivisionId, setDraggingDivisionId] = useState<string | null>(null);
  const [isResolved, setIsResolved] = useState<boolean>(false);
  const [isExecuted, setIsExecuted] = useState<boolean>(false);
  const [isModalOpen, toggleModal] = useToggle(false);
  const [storedColumns, setStoredColumns] = useLocalStorage<DivisionColumn[]>(
    'compare-page-selected-columns',
    defaultCompareColumns
  );
  const [showConflictError, setShowConflictError] = useState<boolean>(false);

  const classes = useStyles();

  useEffect(() => {
    if (!data) return;
    setIsResolved(data.candidateCase.resolved != null);
    if (!isExecuted) setAccounts(data.accounts);
    // eslint-disable-next-line
  }, [data]);

  const [isUndoPressed] = useKeyboardJs(['command + z', 'ctrl + z']);
  useUpdateEffect(() => {
    if (isUndoPressed && accountStateHistory.position > 1) accountStateHistory.back();
  }, [isUndoPressed]);

  const [isRedoPressed] = useKeyboardJs(['shift + command + z', 'shift + ctrl + z']);
  useUpdateEffect(() => {
    if (isRedoPressed) accountStateHistory.forward();
  }, [isRedoPressed]);

  const [isEscPressed] = useKeyboardJs('esc');
  useUpdateEffect(() => {
    if (isEscPressed && selectedDivisionIds.length > 0) unselectAll();
  }, [isEscPressed]);

  const [selectedColumns, setSelectedColumns] = useState<DivisionColumn[]>();
  useEffect(() => {
    setSelectedColumns(storedColumns?.filter(col => col.display));
  }, [storedColumns]);

  const onWindowClick = (event: { defaultPrevented: any }) => {
    if (event.defaultPrevented) return;
    unselectAll();
  };
  useEvent('click', onWindowClick);

  const updateCandidate = async (diffResult: DiffResult, divisionFeedback: DivisionFeedback[]) => {
    const response = await fetch(config.EEMS_API_URL + `/reconciliation-candidates/${candidateId}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify({ diffResult, divisionFeedback }),
    });
    return await response.json();
  };

  const executeReconciliation = async () => {
    setIsExecuted(true);

    const initialAccountState = accountStateHistory.history[1];
    const finalAccountState = accountStateHistory.history[accountStateHistory.position];

    // Generate the case result
    const diffResult = generateCaseResult(data.candidateCase.businessId, initialAccountState, finalAccountState);
    console.log(diffResult);

    // Generate the division feedback
    const divisionFeedback = generateDivisionFeedback(finalAccountState, data.candidateCase.businessId);

    // Make POST request to /reconciliation-candidates/:candidateId
    const response = await updateCandidate(diffResult, divisionFeedback);

    // Mutate local cache based on response
    const newData = cloneDeep(data);
    newData.candidateCase.resolution = response.resolution;
    newData.candidateCase.resolved = response.resolved;
    mutate(newData, false);

    // Check for conflictError field, in case candidate has already been resolved
    if (response.conflictError) {
      setShowConflictError(true);
      return;
    }
    confettiGun();
  };

  const onDragStart = (start: DragStart) => {
    const id = start.draggableId;
    const selected = selectedDivisionIds.find(divisionId => divisionId === id);

    if (!selected) unselectAll();

    setDraggingDivisionId(id);
  };

  const onDragEnd = (result: DropResult) => {
    // If dropped outside either of the lists, reset draggingDivisionId
    if (!result.destination || result.reason === 'CANCEL') {
      setDraggingDivisionId(null);
      return;
    }

    setDraggingDivisionId(null);

    // Use the result.destination.droppableId, to figure out which account/list it was dropped onto
    // Use the result.destination.index, to figure out at which position in the list it was dropped
    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;
    const sourceDroppableId = result.source.droppableId;
    const destDroppableId = result.destination.droppableId;

    // If dropped in the same spot in the same group, make no changes
    if (sourceIndex === destIndex && sourceDroppableId === destDroppableId) return;

    const droppableType = result.type;
    if (droppableType === 'businessGroup') {
      const newAccounts = cloneDeep(accounts);

      if (sourceDroppableId === destDroppableId) {
        // If a business group was dropped within the same account
        const tempAccount = newAccounts.find(account => account.id === destDroppableId);
        if (!tempAccount) return;

        tempAccount.businessGroups = reorder(tempAccount.businessGroups, sourceIndex, destIndex);
        setAccounts(newAccounts);
      } else {
        // If a business group was dropped into an adjacent account
        const tempSourceAccount = newAccounts.find(account => account.id === sourceDroppableId);
        const tempDestinationAccount = newAccounts.find(account => account.id === destDroppableId);

        if (!tempSourceAccount || !tempDestinationAccount) return;

        // Remove the business group from the source account
        const [removed] = tempSourceAccount.businessGroups.splice(sourceIndex, 1);

        // Check whether there's already a business group with that ID in the destination account
        const matchingBusinessGroup = tempDestinationAccount.businessGroups.find(
          businessGroup => businessGroup.id === removed.id
        );
        if (matchingBusinessGroup) {
          // If this new business group shares the same EIS ID as another business group in this account, merge them together.
          // TODO: A merge animation would be suuuper cool, but not sure how do-able.
          matchingBusinessGroup.divisions.push(...removed.divisions);
        } else {
          // Otherwise, add this business group to the destination account
          tempDestinationAccount.businessGroups.splice(destIndex, 0, removed);
        }

        setAccounts(newAccounts);
      }
    } else if (droppableType === 'division') {
      // The droppableId is currently formed as "accountId|businessId"
      const [sourceAccountId, sourceBusinessId] = sourceDroppableId.split('|');
      const [destAccountId, destBusinessId] = destDroppableId.split('|');

      const newAccounts = cloneDeep(accounts);

      if (sourceDroppableId === destDroppableId) {
        // If a division was dropped into the same business group, in a different position
        const tempAccount = newAccounts.find(account => account.id === destAccountId);
        if (!tempAccount) return;

        const tempBusinessGroup = tempAccount.businessGroups.find(businessGroup => businessGroup.id === destBusinessId);
        if (!tempBusinessGroup) return;

        let reorderedDivisions: any[];

        if (selectedDivisionIds.length > 1)
          reorderedDivisions = reorderMultiDrag(
            tempBusinessGroup.divisions,
            selectedDivisionIds,
            draggingDivisionId,
            destIndex
          );
        else {
          reorderedDivisions = reorder(tempBusinessGroup.divisions, sourceIndex, destIndex);
        }
        tempAccount.businessGroups = tempAccount.businessGroups.map(item => {
          if (item.id === destBusinessId) item.divisions = reorderedDivisions;
          return item;
        });
        setAccounts(newAccounts);
      } else {
        // If a division was dropped into a different business group
        // Find the source business group so we can remove the division from it later
        const tempSourceAccount = newAccounts.find(account => account.id === sourceAccountId);
        if (!tempSourceAccount) return;

        const sourceBusinessGroup = tempSourceAccount.businessGroups.find(
          businessGroup => businessGroup.id === sourceBusinessId
        );
        if (!sourceBusinessGroup) return;

        // Helper method to find destination business group
        const findDestBusinessGroup = () => {
          const tempDestinationAccount = newAccounts.find(account => account.id === destAccountId);
          if (!tempDestinationAccount) return;

          const destBusinessGroup = tempDestinationAccount.businessGroups.find(
            businessGroup => businessGroup.id === destBusinessId
          );

          return destBusinessGroup;
        };

        let removed;
        if (selectedDivisionIds.length > 1) {
          // Reorder selectedDivisionIds, so dragged division goes first
          const orderedSelectedDivisionIds = reorderSelectedDivisionIds(selectedDivisionIds, draggingDivisionId);

          // Remove multi-selected divisions from the source business group
          removed = remove(sourceBusinessGroup.divisions, division => selectedDivisionIds.includes(division.uuid));
          removed.sort((a, b) => {
            return orderedSelectedDivisionIds.indexOf(a.uuid) - orderedSelectedDivisionIds.indexOf(b.uuid);
          });

          const destBusinessGroup = findDestBusinessGroup();
          if (!destBusinessGroup) return;

          // Add multi-selected divisions to the destination business group
          destBusinessGroup.divisions.splice(destIndex, 0, ...removed);
        } else {
          // Remove a single division from the source business group
          [removed] = sourceBusinessGroup.divisions.splice(sourceIndex, 1);
          const destBusinessGroup = findDestBusinessGroup();
          if (!destBusinessGroup) return;

          // Add a single division to the destination business group
          destBusinessGroup.divisions.splice(destIndex, 0, removed);
        }

        setAccounts(newAccounts);
      }
    }
  };

  // Selects and de-selects a single division
  const toggleSelection = (divisionId: string) => {
    const wasSelected = selectedDivisionIds.includes(divisionId);
    const newDivisionIds = (() => {
      if (!wasSelected) {
        return [divisionId];
      }
      if (selectedDivisionIds.length > 1) {
        return [divisionId];
      }
      return [];
    })();
    setSelectedDivisionIds(newDivisionIds);
  };

  // Selects and de-selects a single division (when cmd or ctrl-clicking one or more divisions)
  const toggleSelectionInGroup = (divisionId: string, accountId: string, businessId: string) => {
    const lastSelected = selectedDivisionIds[selectedDivisionIds.length - 1];
    const account = accounts.find(account => account.id === accountId);
    const businessGroup = account?.businessGroups.find(group => group.id === businessId);
    const indexOfNew = selectedDivisionIds.indexOf(divisionId);
    const indexOfLast = businessGroup?.divisions.map(division => division.uuid).indexOf(lastSelected);

    // Checks if newly-selected division is in a new business group
    if (indexOfLast === -1) {
      setSelectedDivisionIds([divisionId]);
      return;
    }

    // Checks if newly-selected division has already been selected
    if (indexOfNew === -1) {
      setSelectedDivisionIds([...selectedDivisionIds, divisionId]);
      return;
    }

    // De-selects division if it was already selected
    const shallow = [...selectedDivisionIds];
    shallow.splice(indexOfNew, 1);
    setSelectedDivisionIds(shallow);
  };

  // Selects all in-between divisions (when shift-clicking multiple divisions)
  const multiSelectTo = (newDivisionId: string, accountId: string, businessId: string) => {
    // No divisions already selected, so add new division and do nothing else
    if (!selectedDivisionIds.length) {
      setSelectedDivisionIds([newDivisionId]);
      return;
    }

    // Locate new division's business group
    const account = accounts.find(account => account.id === accountId);
    const businessGroup = account?.businessGroups.find(group => group.id === businessId);

    // Selecting all divisions up to the index of the newest division,
    // so get index of previous and current divisions
    const lastSelected = selectedDivisionIds[selectedDivisionIds.length - 1];
    const indexOfLast = businessGroup?.divisions.map(division => division.uuid).indexOf(lastSelected);
    const indexOfNew = businessGroup?.divisions.map(division => division.uuid).indexOf(newDivisionId);

    // multi selecting to another column
    // select everything up to the index of the current item
    if (indexOfLast === -1) {
      const newGroupDivisions = businessGroup!.divisions.slice(0, indexOfNew! + 1);
      setSelectedDivisionIds(newGroupDivisions.map(d => d.uuid));
      return;
    }

    // If multi selecting the same index, do nothing
    if (indexOfNew === indexOfLast) {
      unselectAll();
      return;
    }

    let inBetween;
    let isSelectingForwards;
    if (indexOfLast !== undefined && indexOfNew !== undefined) {
      isSelectingForwards = indexOfNew > indexOfLast;
      const start = isSelectingForwards ? indexOfLast : indexOfNew;
      const end = isSelectingForwards ? indexOfNew : indexOfLast;
      inBetween = businessGroup?.divisions.slice(start, end + 1);
    }

    // Everything in between start and end indices needs to have its selection toggled
    // (except start and end values, which should always be selected)
    const toAdd = inBetween?.filter(division => {
      // if already selected: then no need to select it again
      if (selectedDivisionIds.includes(division.uuid)) return false;

      return true;
    });

    let sorted = toAdd;
    if (sorted) {
      sorted = isSelectingForwards ? sorted : sorted.reverse();
      const sortedDivisionIds = sorted.map(division => {
        return division.uuid;
      });
      setSelectedDivisionIds([...selectedDivisionIds, ...sortedDivisionIds]);
    }
  };

  const unselectAll = () => {
    if (selectedDivisionIds.length) setSelectedDivisionIds([]);
    if (draggingDivisionId) setDraggingDivisionId(null);
  };

  const handleNewBusiness = (accountId: string) => {
    // find the account related to the new business button that was clicked
    const newAccounts = cloneDeep(accounts);
    const tempDestinationAccount = newAccounts.find(account => account.id === accountId);
    if (!tempDestinationAccount) return;

    // insert a new empty business group into that account's list of business groups
    const tempBusinessId = `New ${Math.round(Math.random() * 1000)}`;
    const tempNewBusinessGroup = { id: tempBusinessId, divisions: [] };
    tempDestinationAccount.businessGroups.splice(0, 0, tempNewBusinessGroup);

    // save the changes to state
    setAccounts(newAccounts);
  };

  const handleMergeAll = (accounts: AccountDetails[], sourceAccountId: string) => {
    const newAccounts = cloneDeep(accounts);
    const sourceIndex = accounts[0].id === sourceAccountId ? 0 : 1;
    const destIndex = accounts[0].id === sourceAccountId ? 1 : 0;

    if (newAccounts[destIndex].businessGroups.length < 1) {
      newAccounts[destIndex].businessGroups = newAccounts[sourceIndex].businessGroups;
      newAccounts[sourceIndex].businessGroups = [];

      setAccounts(newAccounts);
      return;
    }

    accounts[sourceIndex].businessGroups.forEach(() => {
      // Remove business group from source account
      const removed = newAccounts[sourceIndex].businessGroups.shift();

      if (removed) {
        // Check whether there's already a business group with that ID in the destination account
        const matchingBusinessGroup = newAccounts[destIndex].businessGroups.find(
          businessGroup => businessGroup.id === removed.id
        );

        if (matchingBusinessGroup) {
          // If this new business group shares the same EIS ID as another business group in this account, merge them together.
          matchingBusinessGroup.divisions.push(...removed.divisions);
        } else {
          // Otherwise, add this business group to the destination account
          newAccounts[destIndex].businessGroups.push(removed);
        }
      }
    });

    setAccounts(newAccounts);
  };

  const updateStoredColumns = (changedColumn: string) => {
    // Make a clone of storedColumns to avoid editing state value directly
    const tempColumns = cloneDeep(storedColumns);

    // Find the column that changed in storedColumns
    const foundColumn = tempColumns?.find(c => c.name === changedColumn);

    if (foundColumn) {
      // Update display value to false or true
      foundColumn.display = !foundColumn.display;
      setStoredColumns(tempColumns);
    }
  };

  const resetStoredColumns = () => setStoredColumns(defaultCompareColumns);

  if (error) return <h1>Something went wrong!</h1>;
  if (!accounts.length) return <h1>Loading...</h1>;

  return (
    <>
      <Box display="flex" p={2} pb={0}>
        <Alert
          className={classes.conflictErrorAlert}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          title="Conflict Error"
          variant="error"
          open={showConflictError}
          onClose={() => setShowConflictError(false)}
        >
          This candidate has already been resolved.
        </Alert>
        <Button
          variant="outlined"
          color="secondary"
          disabled={accountStateHistory.position <= 1 || isResolved}
          onClick={() => accountStateHistory.back()}
          startIcon={<Undo />}
        >
          Undo
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          disabled={accountStateHistory.position === accountStateHistory.history.length - 1}
          onClick={() => accountStateHistory.forward()}
          startIcon={<Redo />}
        >
          Redo
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          disabled={isResolved}
          onClick={() => accountStateHistory.go(1)}
          startIcon={<Restore />}
        >
          Reset
        </Button>
        <Box mr={0} ml="auto">
          {isResolved ? (
            <Box className={classes.resolvedInfo}>
              <TypeBase align="trailing" spacingBottom={0.5}>
                <b>Resolution</b>: {data.candidateCase.resolution}
              </TypeBase>
              <TypeBase align="trailing">
                {formatDate(new Date(data.candidateCase.resolved))}, {formatTime(new Date(data.candidateCase.resolved))}
              </TypeBase>
            </Box>
          ) : (
            <>
              <Button variant="outlined" className={classes.selectColumnsButton} onClick={toggleModal}>
                Select Columns
              </Button>
              <Modal
                open={isModalOpen}
                onClose={toggleModal}
                modalTitle="Select Columns"
                actionsContent={
                  <Button color="primary" onClick={resetStoredColumns}>
                    Reset to Default
                  </Button>
                }
              >
                <Box mb={3}>
                  <ul className={classes.selectColumnsList}>
                    {storedColumns?.map(col => (
                      <li key={col.name}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={col.display}
                              onChange={() => updateStoredColumns(col.name)}
                              color="primary"
                              name={col.name}
                            />
                          }
                          label={col.label}
                        />
                      </li>
                    ))}
                  </ul>
                </Box>
              </Modal>
              <Button 
                disabled={isExecuted}
                onClick={() => executeReconciliation()}
              >
                Execute
                {isExecuted && <CircularProgress size={24} className={classes.buttonProgress} />}
              </Button>
            </>
          )}
        </Box>
      </Box>
      <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
        <Box display="grid" gridTemplateColumns="1fr 1fr" p={2} gridGap={16}>
          {accounts.map((account, index) => (
            <Box
              key={account.id}
              display="flex"
              flexDirection="column"
              border="1px solid #979797"
              bgcolor="#f5f5f5"
              height="87vh"
            >
              <Box
                borderBottom="1px solid #979797"
                bgcolor="#ebebeb"
                px={2}
                py={1.5}
                display="grid"
                gridTemplateColumns="3fr 1fr"
              >
                <Box>
                  <TypeSubsectionHeader>{account.name}</TypeSubsectionHeader>
                  <TypeHelper>
                    <b>Owner:</b> {account.owner} ({account.ownerTeam})
                  </TypeHelper>
                  <TypeHelper>
                    <b>Website:</b> <AccountWebsiteLink website={account.website} />
                  </TypeHelper>
                  <TypeHelper>
                    <SalesforceAccountLink accountId={account.id} accountName={account.id} /> (
                    {account.businessGroups.length} {pluralize(account.businessGroups.length, 'entity', 'entities')})
                  </TypeHelper>
                </Box>
                <Box display="grid" alignContent="baseline" justifyItems="end" gridGap="0.5em">
                  <Button
                    className={classes.accountHeaderButton}
                    size="small"
                    variant="outlined"
                    color="secondary"
                    disabled={account.businessGroups.length < 1 || isResolved}
                    onClick={() => handleMergeAll(accounts, account.id)}
                    endIcon={index === 0 ? <ArrowRightIcon /> : undefined}
                    startIcon={index === 1 ? <ArrowLeftIcon /> : undefined}
                  >
                    Merge All
                  </Button>
                  <Button
                    className={classes.accountHeaderButton}
                    size="small"
                    variant="outlined"
                    disabled={isResolved}
                    onClick={() => handleNewBusiness(account.id)}
                    startIcon={<AddIcon />}
                  >
                    New Business
                  </Button>
                </Box>
              </Box>
              <Droppable droppableId={account.id} type="businessGroup">
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                    {account.businessGroups.map((item, index) => {
                      const materialColors200 = toMaterialStyle(item.id, 200);
                      return (
                        <Draggable
                          key={item.id}
                          draggableId={`${account.id}|${item.id}`}
                          index={index}
                          isDragDisabled={isResolved}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(item.id, snapshot.isDragging, provided.draggableProps.style)}
                            >
                              <Box
                                display="flex"
                                py={1}
                                px={2}
                                borderBottom={`2px solid ${materialColors200.backgroundColor}`}
                              >
                                {item.id === data.candidateCase.businessId && (
                                  <Stars
                                    style={{
                                      marginRight: 8,
                                      marginLeft: -8,
                                    }}
                                  />
                                )}
                                <TypeWidgetHeader>EIS-ID: {item.id}</TypeWidgetHeader>&nbsp;
                                <TypeHelper>
                                  ({item.divisions.length} {pluralize(item.divisions.length, 'division', 'divisions')})
                                </TypeHelper>
                              </Box>
                              <DivisionTableDroppable
                                divisions={item.divisions}
                                businessId={item.id}
                                accountId={account.id}
                                isResolved={isResolved}
                                multiSelectTo={multiSelectTo}
                                toggleSelection={toggleSelection}
                                toggleSelectionInGroup={toggleSelectionInGroup}
                                selectedDivisionIds={selectedDivisionIds}
                                draggingDivisionId={draggingDivisionId}
                                selectedColumns={selectedColumns!}
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </Box>
          ))}
        </Box>
      </DragDropContext>
    </>
  );
};

const pluralize = (count: number, singular: string, plural: string): string => (count === 1 ? singular : plural);

const useStyles = makeStyles<Theme>(theme => ({
  accountHeaderButton: {
    justifyContent: 'space-between',
    width: '12em',
  },
  resolvedInfo: {
    '&>p': {
      lineHeight: '1',
    },
  },
  selectColumnsList: {
    listStyleType: 'none',
  },
  selectColumnsButton: {
    marginRight: '1em',
  },
  conflictErrorAlert: {
    marginTop: '2em',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    right: '50%',
    marginTop: -12,
    marginRight: -12,
  },
}));
