import React, { FC, useState, useEffect } from 'react';
import { Box, Button, Tooltip, DownloadIcon, makeStyles, Theme, Modal } from '@c2fo/react-components';
import { CSVLink } from 'react-csv';
import { useToggle } from 'react-use';
import { cloneDeep } from 'lodash';

import { UploadDropArea } from './UploadDropArea';
import { UndoConfirmationDialog } from './UndoConfirmationDialog';
import {
  DivestCaseCsvRow,
  HistoryTableToolbarProps,
  MergeCaseCsvRow,
  DivestSuccessLog,
  Step2DivestCaseCsvRow,
} from '../../schemas/history';
import { DivestActionBlob, MergeActionBlob, PartialDivision } from '../../schemas/compare';
import { config } from '../../config';
import { getToken } from '../../helpers/auth';

const updateReconciliationHistory = async (idList: number[]) => {
  if (!idList.length) return;
  const response = await fetch(config.EEMS_API_URL + '/reconciliation-history/download', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-type': 'application/json; charset=UTF-8',
    },
    body: JSON.stringify({ idList }),
  });
  return await response.json();
};

const undoReconciliationHistory = async (idList: number[]) => {
  if (!idList.length) return;
  const response = await fetch(config.EEMS_API_URL + '/reconciliation-history/undo', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-type': 'application/json; charset=UTF-8',
    },
    body: JSON.stringify({ idList }),
  });
  return await response.json();
};

export const HistoryTableToolbar: FC<HistoryTableToolbarProps> = ({
  data,
  selectedRows,
  mutateHistoryRows,
  resetRowsSelected,
}) => {
  const [mergeCsvData, setMergeCsvData] = useState<MergeCaseCsvRow[]>([]);
  const [divestCsvData, setDivestCsvData] = useState<DivestCaseCsvRow[]>([]);
  const [step2DivestCsvData, setStep2DivestCsvData] = useState<Step2DivestCaseCsvRow[]>([]);
  const [showMergeButton, setShowMergeButton] = useState(false);
  const [showDivestButton, setShowDivestButton] = useState(false);
  const [isModalOpen, toggleModal] = useToggle(false);

  useEffect(() => {
    const tempSelected: string[] = selectedRows.data.map(row => {
      const individualCase = data[row.dataIndex];
      return individualCase.actionType;
    });
    setShowMergeButton(tempSelected.includes('merge'));
    setShowDivestButton(tempSelected.includes('divest'));
  }, [selectedRows, data]);

  const classes = useStyles();

  const onDivestClick = () => {
    const newData = cloneDeep(data);
    const idList: number[] = [];

    const tempDivestCsvData: DivestCaseCsvRow[] = selectedRows.data
      .filter(row => data[row.dataIndex].actionType === 'divest')
      .map(row => {
        const isolatedCase = data[row.dataIndex];
        const divestAction = isolatedCase.action as DivestActionBlob;
        const caseData: DivestCaseCsvRow = {
          RecordTypeId: '012120000019F8fAAE',
          Status: 'New',
          Subject: `EEMS Divest ${isolatedCase.created}`,
          AccountId: divestAction.fromAccountId,
          Divest_To_Account__c: divestAction.toAccountId,
          HistoryId: isolatedCase.id,
        };

        if (!newData[row.dataIndex].downloaded) {
          idList.push(isolatedCase.id);
          newData[row.dataIndex].downloaded = true;
        }

        return caseData;
      });
    setDivestCsvData(tempDivestCsvData);
    toggleModal();

    mutateHistoryRows(newData);
    updateReconciliationHistory(idList);
  };

  const onMergeClick = () => {
    const newData = cloneDeep(data);
    const idList: number[] = [];

    let tempMergeCsvData: MergeCaseCsvRow[] = selectedRows.data
      .filter(row => data[row.dataIndex].actionType === 'merge')
      .map(row => {
        const isolatedCase = data[row.dataIndex];
        const mergeAction = isolatedCase.action as MergeActionBlob;
        const caseData: MergeCaseCsvRow = {
          Id: mergeAction.fromAccountId,
          Master_Account__c: mergeAction.toAccountId,
          Create_Case_Status__c:
            mergeAction.toAccountOwner === mergeAction.fromAccountOwner ? 'Approved' : 'Pending Approval',
          Create_Case_Subject__c: `EEMS Merge ${isolatedCase.created}`,
        };

        if (!newData[row.dataIndex].downloaded) {
          idList.push(isolatedCase.id);
          newData[row.dataIndex].downloaded = true;
        }

        return caseData;
      });

    const seenRows = new Set();
    tempMergeCsvData = tempMergeCsvData.filter(csvRow => {
      const comboId = [csvRow.Id, csvRow.Master_Account__c].sort().join('|');

      if (seenRows.has(comboId)) {
        console.log(`Found duplicate: ${csvRow.Id} -> ${csvRow.Master_Account__c}`);
        return false;
      }
      return seenRows.add(comboId);
    });

    setMergeCsvData(tempMergeCsvData);

    mutateHistoryRows(newData);
    updateReconciliationHistory(idList);
  };

  const onUndoClick = () => {
    const newData = cloneDeep(data);

    const idList: number[] = selectedRows.data.map(row => {
      newData.splice(row.dataIndex, 1);
      return data[row.dataIndex].id;
    });

    mutateHistoryRows(newData);
    undoReconciliationHistory(idList);
    resetRowsSelected();
  };

  const onModalClose = () => {
    toggleModal();
    setStep2DivestCsvData([]);
  };

  const generateFinalDivestCSV = (successLogs: DivestSuccessLog[]) => {
    const step2Rows: Step2DivestCaseCsvRow[] = [];
    successLogs.forEach(successLog => {
      const selectedRow = selectedRows.data.find(row => data[row.dataIndex].id === successLog.HISTORYID);
      if (selectedRow) {
        const selectedRowData = data[selectedRow.dataIndex];
        const selectedRowAction = selectedRowData.action as DivestActionBlob;
        selectedRowAction.divisions.forEach((division: PartialDivision) => {
          step2Rows.push({ Case__c: successLog.ID, Division__c: division.divisionId });
        });
      }
    });

    setStep2DivestCsvData(step2Rows);
  };

  return (
    <Box className={classes.downloadButtonsContainer} paddingRight={3} paddingTop={0.75} paddingBottom={0.75}>
      <UndoConfirmationDialog onConfirm={onUndoClick} />
      {showMergeButton && (
        <Tooltip title={'Download Merge Cases'}>
          <CSVLink
            data={mergeCsvData}
            uFEFF={false}
            enclosingCharacter={''}
            filename={`${new Date().toISOString()}-mergeCases.csv`}
            className={classes.link}
          >
            <Button color="secondary" onClick={onMergeClick} startIcon={<DownloadIcon />}>
              Merge Case CSV
            </Button>
          </CSVLink>
        </Tooltip>
      )}
      {showDivestButton && (
        <Tooltip title={'Download Divest Cases'}>
          <CSVLink
            data={divestCsvData}
            uFEFF={false}
            enclosingCharacter={''}
            filename={`${new Date().toISOString()}-divestCases.csv`}
            className={classes.link}
          >
            <Button color="secondary" onClick={onDivestClick} startIcon={<DownloadIcon />}>
              Divest Case CSV
            </Button>
          </CSVLink>
        </Tooltip>
      )}
      <Modal
        open={isModalOpen}
        onClose={onModalClose}
        modalTitle="Divest CSV"
        actionsContent={
          step2DivestCsvData.length ? (
            <CSVLink
              data={step2DivestCsvData}
              uFEFF={false}
              enclosingCharacter={''}
              filename={`${new Date().toISOString()}-divestCases-step2.csv`}
              className={classes.link}
            >
              <Button color="secondary" startIcon={<DownloadIcon />}>
                Final Divest CSV
              </Button>
            </CSVLink>
          ) : (
            <Button disabled color="secondary" startIcon={<DownloadIcon />}>
              Final Divest CSV
            </Button>
          )
        }
      >
        <UploadDropArea onSuccessfulUpload={generateFinalDivestCSV} />
      </Modal>
    </Box>
  );
};

const useStyles = makeStyles<Theme>(() => ({
  link: {
    textDecoration: 'none',
  },
  downloadButtonsContainer: {
    '&> a + a': {
      marginLeft: '12px',
    },
  },
}));
