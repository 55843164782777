import React, { FC } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params'
import { C2foComponentsRootProvider } from '@c2fo/react-components';

import Router from './Router';
import { Nav, StoreToken } from './components';

const App: FC = () => (
  <C2foComponentsRootProvider>
    <BrowserRouter>
    <QueryParamProvider ReactRouterRoute={Route}>
      <StoreToken>
        <Nav />
        <Router />
      </StoreToken>
    </QueryParamProvider>
    </BrowserRouter>
  </C2foComponentsRootProvider>
);

export default App;
