import { AccountDetails, DivisionFeedback } from '../schemas/compare';

// Need to check for each of these scenarios to generate accurate feedback for EIS:
// 1. A business group's list of divisions changed
// 2. A business group moved to a different account
// 3. A new (non-empty) business group has been created
// 4. The crossover business group is still split across accounts

export const generateDivisionFeedback = (
  finalAccountState: AccountDetails[],
  crossoverBusinessId: string
): DivisionFeedback[] => {
  // We can work off of a flatMap list of all the divisions from just the finalAccountState, keeping each division's original businessId
  // and accountId (from the database record), as well as additional properties for their current values (where they landed in the app),
  // as "newBusinessId" and "newAccountId".
  const combinedDivisionMap = getDivisionFlatMap(finalAccountState);

  // When we find a division where one of those "new" properties doesn't match the original, then:
  // Add that division's businessId and newBusinessId to a set (unique list) of modified business groups to be assessed in the next step.
  const modifiedBusinessGroupIds = new Set();
  combinedDivisionMap.forEach(division => {
    if (division.businessId !== division.newBusinessId || division.accountId !== division.newAccountId) {
      modifiedBusinessGroupIds.add(division.businessId);
      modifiedBusinessGroupIds.add(division.newBusinessId);
    }
  });

  // The crossover business group would need to be treated as a special case, whether any of its divisions moved or not.
  // We can handle this by always adding the crossover businessId to the list of business groups to be processed.
  modifiedBusinessGroupIds.add(crossoverBusinessId);

  // Loop over the businessGroups in each account, and upon finding a business id belonging to the modifiedBusinessGroupIds set,
  // create a DivisionFeedback object as described below, with the accountId of the account currently being looped over:
  // {
  //     divisions: ['1bbb4ff8-adfc-4fd2-8bd6-b61433cc7630', '9d172dbf-a776-4765-b566-f5bbf35892a5'], // list of uuid per division in this group
  //     accountId: '0011200001DysTgAAJ'
  // }
  const feedbackRows: DivisionFeedback[] = [];
  finalAccountState.forEach(account => {
    account.businessGroups.forEach(businessGroup => {
      if (modifiedBusinessGroupIds.has(businessGroup.id) && businessGroup.divisions.length) {
        feedbackRows.push({
          divisions: businessGroup.divisions.map(division => division.uuid),
          accountId: account.id,
        });
      }
    });
  });

  return feedbackRows;
};

function getDivisionFlatMap(accounts: AccountDetails[]) {
  return accounts.flatMap(account =>
    account.businessGroups.flatMap(businessGroup =>
      businessGroup.divisions.map(division => ({
        divisionId: division.uuid,
        businessId: division.businessId.toString(),
        accountId: division.accountId,
        newBusinessId: businessGroup.id,
        newAccountId: account.id,
      }))
    )
  );
}
