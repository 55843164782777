import React, { FC } from 'react';
import { Link } from '@c2fo/react-components';

interface SalesforceDivisionLinkProps {
  divisionId: string;
  divisionName: string;
}

export const SalesforceDivisionLink: FC<SalesforceDivisionLinkProps> = ({ divisionId, divisionName }) => (
  <Link
    href={`https://c2fo.lightning.force.com/lightning/r/Division__c/${divisionId}/view`}
    target="_blank"
    rel="noopener noreferrer"
  >
    {divisionName}
  </Link>
);
