import React, { FC, useRef } from 'react';
import {
  TypeLegalese,
  TypeBase,
  CircularProgress,
  Autocomplete,
  TextField,
  MenuItem,
  C2FOTheme,
} from '@c2fo/react-components';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import MUIDataTable, { MUIDataTableOptions, MUIDataTableColumn, MUISortOptions } from 'mui-datatables';

import { LinkButton, SalesforceAccountLink } from '../../components';
import { formatCurrency } from '../../helpers/currency';
import { formatDate } from '../../helpers/dateTime';
import { ReconciliationCandidatesTableProps, BuyerListItem } from '../../schemas/reconciliationCandidates';
import { useRequest } from '../../hooks';

const eisSuggestionOptions = [
  { value: 'merge', label: 'Likely Merge Cases' },
  { value: 'divest', label: 'Likely Divest Cases' },
  { value: 'reverse_merge', label: 'Reverse Merge Cases' },
  { value: 'reverse_divest', label: 'Reverse Divest Cases' },
];

export const ReconciliationCandidatesTable: FC<ReconciliationCandidatesTableProps> = ({
  candidates,
  isLoading,
  onColumnSortChange,
  onChangePage,
  onChangeRowsPerPage,
  totalCount,
  query,
  onResetFilters,
  onChangeFilter,
}) => {
  const { data: buyerList } = useRequest('/buyers');
  const accountIdFilterRef = useRef<HTMLTextAreaElement>(null);

  const candidatesColumns: MUIDataTableColumn[] = [
    {
      name: 'accountBName',
      label: 'Candidate Account',
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex: number) => {
          const candidate = candidates[dataIndex];
          return (
            <>
              <SalesforceAccountLink accountId={candidate.accountBId} accountName={candidate.accountBName} />
              <TypeLegalese>{candidate.accountBOwner}</TypeLegalese>
            </>
          );
        },
      },
    },
    {
      name: 'accountBCreated',
      label: 'Candidate Created',
      options: {
        filter: false,
        // display: 'false',
        customBodyRenderLite: (dataIndex: number) => {
          const candidate = candidates[dataIndex];
          return formatDate(new Date(candidate.accountBCreated));
        },
      },
    },
    {
      name: 'accountBCrossoverAp',
      label: 'Candidate AP',
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex: number) => {
          const candidate = candidates[dataIndex];
          return formatCurrency(candidate.accountBCrossoverAp, { compact: true });
        },
      },
    },
    {
      name: 'accountBSize',
      label: 'Candidate Divisions',
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex: number) => {
          const candidate = candidates[dataIndex];
          return (
            <>
              {candidate.accountBCrossover}
              <TypeLegalese>(of {candidate.accountBSize})</TypeLegalese>
            </>
          );
        },
      },
    },
    {
      name: 'accountAName',
      label: 'Target Account',
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex: number) => {
          const candidate = candidates[dataIndex];
          return (
            <>
              <SalesforceAccountLink accountId={candidate.accountAId} accountName={candidate.accountAName} />
              <TypeLegalese>{candidate.accountAOwner}</TypeLegalese>
            </>
          );
        },
      },
    },
    {
      name: 'accountACreated',
      label: 'Target Created',
      options: {
        filter: false,
        // display: 'false',
        customBodyRenderLite: (dataIndex: number) => {
          const candidate = candidates[dataIndex];
          return formatDate(new Date(candidate.accountACreated));
        },
      },
    },
    {
      name: 'accountACrossoverAp',
      label: 'Target AP',
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex: number) => {
          const candidate = candidates[dataIndex];
          return formatCurrency(candidate.accountACrossoverAp, { compact: true });
        },
      },
    },
    {
      name: 'accountASize',
      label: 'Target Divisions',
      options: {
        filter: false,
        customBodyRenderLite: (dataIndex: number) => {
          const candidate = candidates[dataIndex];
          return (
            <>
              {candidate.accountACrossover}
              <TypeLegalese>(of {candidate.accountASize})</TypeLegalese>
            </>
          );
        },
      },
    },
    {
      name: '',
      options: {
        viewColumns: false,
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: dataIndex => {
          const candidate = candidates[dataIndex];
          return (
            <LinkButton size="small" to={`/reconcile/candidate/${candidate.id}`}>
              Compare
            </LinkButton>
          );
        },
      },
    },
    {
      name: 'eisSuggestion',
      options: {
        filterList: query.eisSuggestion ? [query.eisSuggestion] : [],
        display: 'excluded',
        filterType: 'custom',
        customFilterListOptions: {
          render: v => {
            const label = eisSuggestionOptions.find(option => option.value === v[0])?.label;
            return `EIS Suggestion: ${label}`;
          },
        },
        filterOptions: {
          fullWidth: true,
          display: (filterList, onChange, index, column) => (
            <TextField
              select
              value={query.eisSuggestion}
              label="EIS Suggestion"
              variant="outlined"
              color="secondary"
              onChange={event => {
                onChange([event.target.value], index, column);
              }}
            >
              {eisSuggestionOptions.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          ),
        },
      },
    },
    {
      name: 'buyer',
      options: {
        filterList: query.buyerId ? [query.buyerId] : [],
        display: 'excluded',
        filterType: 'custom',
        customFilterListOptions: {
          render: v => {
            const label = buyerList?.find((option: BuyerListItem) => option.value === v[0])?.label;
            return `Buyer: ${label}`;
          },
        },
        filterOptions: {
          fullWidth: true,
          display: (filterList, onChange, index, column) => (
            <Autocomplete
              options={buyerList}
              value={buyerList?.find((option: BuyerListItem) => option.value === query.buyerId) || null}
              getOptionLabel={option => option.label}
              onChange={(_, value) => {
                onChange(value ? [value.value] : [], index, column);
              }}
              renderInput={params => <TextField {...params} variant="outlined" color="secondary" label="Buyer" />}
            />
          ),
        },
      },
    },
    {
      name: 'accountId',
      options: {
        filterList: query.accountId ? [query.accountId] : [],
        display: 'excluded',
        filterType: 'custom',
        customFilterListOptions: {
          render: v => `Account ID: ${v}`,
        },
        filterOptions: {
          fullWidth: true,
          display: (filterList, onChange, index, column) => (
            <TextField
              variant="outlined"
              color="secondary"
              label="Account ID"
              placeholder="Press Enter to submit"
              defaultValue={query.accountId}
              onKeyDown={event => {
                if (event.key === 'Enter') {
                  const target = event.target as HTMLTextAreaElement;
                  onChange([target.value], index, column);
                }
              }}
              inputRef={accountIdFilterRef}
            />
          ),
        },
      },
    },
    {
      name: 'supportOwner',
      label: 'Supplier Support Accounts',
      options: {
        filterList: query.supportOwner ? [query.supportOwner] : [],
        display: 'excluded',
        filterType: 'checkbox',
        customFilterListOptions: {
          render: () => `Supplier Support Accounts`,
        },
        filterOptions: {
          names: ['Required'],
        },
      },
    },
  ];

  const options: MUIDataTableOptions = {
    serverSide: true,
    download: false,
    search: false,
    print: false,
    viewColumns: true,
    selectableRows: 'none',
    textLabels: {
      body: { noMatch: isLoading ? 'Loading...' : 'Sorry, no matching records found' },
    },
    setTableProps: () => ({ size: 'small' }),
    page: query.page,
    onChangePage,
    rowsPerPageOptions: [10, 25, 100],
    rowsPerPage: query.rowsPerPage,
    onChangeRowsPerPage,
    count: totalCount,
    sortOrder: { name: query.sortColumn, direction: query.sortDirection as MUISortOptions['direction'] },
    onColumnSortChange,
    onFilterChange: (column, filterList, type) => {
      if (type === 'reset') {
        // Empty the Account ID input value (it's not directly linked to the query variable)
        if (accountIdFilterRef.current) accountIdFilterRef.current.value = '';
        onResetFilters();
      } else if (column) onChangeFilter(column as string, filterList);
    },
  };

  return (
    <MuiThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        title={
          <TypeBase component="h6" style={{ fontSize: '1.25rem', fontWeight: 500 }}>
            Reconciliation Candidates
            {isLoading && (
              <CircularProgress
                size="20px"
                color="secondary"
                style={{ marginLeft: '16px', position: 'relative', top: '4px' }}
              />
            )}
          </TypeBase>
        }
        data={candidates}
        columns={candidatesColumns}
        options={options}
      />
    </MuiThemeProvider>
  );
};

const getMuiTheme = () =>
  createMuiTheme(C2FOTheme, {
    overrides: {
      MuiGridListTile: {
        tile: {
          overflow: 'unset',
        },
      },
      MUIDataTableFilter: {
        root: { minWidth: '523.5px' },
      },
    },
  });
