import React, { FC } from 'react';
import {
  ExpansionPanel,
  TypeBase,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  IconButton,
  Theme,
  makeStyles,
  TrashIcon,
  AngleDownIcon,
  Box,
} from '@c2fo/react-components';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';

import { ComparisonPanelProps } from '../../schemas/lookup';

const options: MUIDataTableOptions = {
  download: false,
  print: false,
  search: false,
  filter: false,
  viewColumns: false,
  sort: false,
  pagination: false,
  selectableRowsHeader: false,
  selectableRows: 'none',
  elevation: 0,
};

export const ComparisonPanel: FC<ComparisonPanelProps> = ({
  useGlobalColumns,
  accountForComparison,
  onClearAccountsForComparison,
}) => {
  const [globalColumns] = useGlobalColumns();
  const classes = useStyles();
  return (
    <Box p={3} pb={0}>
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<AngleDownIcon />}>
          <IconButton color="secondary" onClick={onClearAccountsForComparison}>
            <TrashIcon />
          </IconButton>
          <Box alignSelf="center">
            <TypeBase>{accountForComparison.name}</TypeBase>
          </Box>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.expansionPanelDetails}>
          <MUIDataTable columns={globalColumns} data={[accountForComparison]} title={''} options={options} />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Box>
  );
};

const useStyles = makeStyles<Theme>(() => ({
  expansionPanelDetails: {
    display: 'block',
    padding: 0,
  },
}));
