const { NumberFormat } = Intl;

interface NumberFormatOptions extends Intl.NumberFormatOptions {
  // These valid options won't be added to TypeScript core types until v4
  // https://github.com/microsoft/TypeScript/pull/37721#issuecomment-644861405
  notation?: string;
  compactDisplay?: string;
  unit?: string;
  unitDisplay?: string;
}

interface CurrencyFormatOptions {
  compact?: boolean;
  stripDecimals?: boolean;
}

export function formatCurrency(amount: number, options: CurrencyFormatOptions = {}) {
  const formatOptions: NumberFormatOptions = {
    style: 'currency',
    currency: 'USD',
  };

  if (options.compact) {
    formatOptions.notation = 'compact';
    formatOptions.minimumFractionDigits = 1;
    formatOptions.maximumFractionDigits = 1;
  }

  if (options.stripDecimals || amount === 0) {
    formatOptions.minimumFractionDigits = 0;
    formatOptions.maximumFractionDigits = 0;
  }

  return NumberFormat('en', formatOptions).format(amount);
}
