import React, { FC } from 'react';
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from 'mui-datatables';
import { TableCell, TableRow } from '@c2fo/react-components';

import { SalesforceDivisionLink } from '../../components';
import { mockDivisionData } from '../../helpers/mockData';
import { ExpandableRowsProps } from '../../schemas/lookup';

const expandedDivisionColumns: MUIDataTableColumn[] = [
  {
    name: 'name',
    label: 'Name',
    options: {
      customBodyRenderLite: (dataIndex: number) => (
        <SalesforceDivisionLink
          divisionId={mockDivisionData[dataIndex].divisionId}
          divisionName={mockDivisionData[dataIndex].name}
        />
      ),
      filter: false,
    },
  },
  { name: 'divisionId', label: 'Division ID', options: { display: false } },
  { name: 'address1', label: 'Address' },
  { name: 'dnbDomain', label: 'DNB Domain' },
  { name: 'userDomain', label: 'User Domain' },
  { name: 'taxId', label: 'Tax ID' },
];

const expandableOptions: MUIDataTableOptions = {
  download: false,
  print: false,
  search: false,
  filter: false,
  viewColumns: false,
  selectableRowsHeader: false,
  rowsPerPage: 5,
  rowsPerPageOptions: [5],
  selectableRows: 'none',
};

export const ExpandableRows: FC<ExpandableRowsProps> = ({ colSpan, tableData }) => {
  return (
    <TableRow>
      <TableCell colSpan={colSpan}>
        <MUIDataTable title={''} data={tableData} columns={expandedDivisionColumns} options={expandableOptions} />
      </TableCell>
    </TableRow>
  );
};
