import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  TypeSubsectionHeader,
  Select,
  makeStyles,
  Theme,
  SearchBar,
  Box,
  TypeHelper,
  Paper,
} from '@c2fo/react-components';
import * as Yup from 'yup';
import { LookupFormFieldProps } from '../../schemas/lookup';

const accountLookupSchema = Yup.object({
  dropSelectValue: Yup.string().required(),
  textInputValue: Yup.string()
    .required()
    .when('dropSelectValue', {
      is: 'accountId',
      then: Yup.string().matches(/^\w+$/).length(18),
      otherwise: Yup.string().min(1),
    }),
});

export const AccountLookup: FC<LookupFormFieldProps> = ({ lookupBy, lookupValue, activeSearchModule }) => {
  const classes = useStyles();
  const history = useHistory();

  const [dropSelectValue, setDropSelectValue] = useState<string>((activeSearchModule && lookupBy) || 'name');
  const [textInputValue, setTextInputValue] = useState<string>((activeSearchModule && lookupValue) || '');
  const [formError, setFormError] = useState<string>('');

  const handleAccountSearch = async () => {
    setFormError('');
    const formValid = await accountLookupSchema.isValid({
      dropSelectValue,
      textInputValue,
    });

    if (formValid) {
      const path = `/lookup/account/${dropSelectValue}/${textInputValue}`;
      history.push(path);
    } else {
      if (dropSelectValue === 'name') setFormError('Please enter a search query');
      if (dropSelectValue === 'accountId') setFormError('Please enter a valid Salesforce Account ID');
    }
  };

  useEffect(() => {
    // When the active search module changes, reset the form
    if (!activeSearchModule) {
      setFormError('');
      setTextInputValue('');
    }
  }, [activeSearchModule, lookupValue]);

  return (
    <Paper elevation={4}>
      <Box p={2}>
        <TypeSubsectionHeader spacingBottom={1}>Account Lookup</TypeSubsectionHeader>
        <Box display="flex">
          <Select
            className={classes.select}
            onChange={event => setDropSelectValue(event.target.value as string)}
            options={[
              {
                label: 'Name',
                value: 'name',
              },
              {
                label: 'Account ID',
                value: 'accountId',
              },
            ]}
            value={dropSelectValue}
          />

          <SearchBar
            onChange={setTextInputValue}
            onClear={() => setTextInputValue('')}
            onSubmit={handleAccountSearch}
            value={textInputValue}
            showCloseIcon={true}
          />
        </Box>
        <span className={classes.errorMessage}>{formError ?? <TypeHelper isError>{formError}</TypeHelper>}</span>
      </Box>
    </Paper>
  );
};

const useStyles = makeStyles<Theme>(() => ({
  errorMessage: {
    color: 'red', // TODO: Figure out why this is necessary, isError should change TypeHelper to red
  },
  select: {
    minWidth: 120,
  },
}));
