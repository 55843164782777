import decode from 'jwt-decode';
import { capitalize } from 'lodash';

import { config } from '../config';
import * as Token from '../schemas/token';

const {
  C2FO_ADMIN_LOGIN_REDIRECT_PATH,
  C2FO_ADMIN_UI_URL,
  EEMS_UI_URL,
  LOCALHOST_URL,
  TOKEN_QUERY_PARAM,
  TOKEN_STORAGE_KEY,
} = config;

const LOGIN_REDIRECT_URL_BASE = `${C2FO_ADMIN_UI_URL}${C2FO_ADMIN_LOGIN_REDIRECT_PATH}${EEMS_UI_URL}`;

export function setToken(token: string): void {
  localStorage.setItem(TOKEN_STORAGE_KEY, token);
}

export function getToken(): string {
  return localStorage.getItem(TOKEN_STORAGE_KEY) || '';
}

function clearToken(): void {
  localStorage.removeItem(TOKEN_STORAGE_KEY);
}

export function redirectToAdminLogin(): void {
  clearToken();
  let hostname = LOGIN_REDIRECT_URL_BASE;
  if (window.location.hostname === 'localhost') hostname += '%2Flocalhost';
  window.location.assign(
    `${hostname}${encodeURIComponent(
      window.location.pathname + window.location.search
    )}&tokenQueryParam=${TOKEN_QUERY_PARAM}`
  );
}

export function redirectToLocalhost(): void {
  const route = window.location.pathname.replace('/localhost', '');
  window.location.assign(
    `${LOCALHOST_URL}${route}?${TOKEN_QUERY_PARAM}=${getToken()}${window.location.search.replace('?', '&')}`
  );
}

export function logout(): void {
  clearToken();
  window.location.assign(`${C2FO_ADMIN_UI_URL}/logout`);
}

function getTokenContent(): Token.Content | null {
  try {
    return decode(getToken());
  } catch (error) {
    return null;
  }
}

export function isTokenExpired(): boolean {
  const token = getTokenContent();

  if (token?.exp) return token.exp < Date.now() / 1000;
  return true;
}

export function getUserFullName(): string {
  const token = getTokenContent();
  const userEmail = token?.payload.user.emailAddress;
  if (!userEmail) return '';

  const splitEmailAddress = userEmail.split('@')[0].split('.');
  return splitEmailAddress.map(s => capitalize(s)).join(' ');
}

export function isAuthenticated(): boolean {
  return !isTokenExpired();
}
