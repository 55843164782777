import React, { FC } from 'react';
import { Box, Button, Tooltip, CompareHorizontalIcon } from '@c2fo/react-components';
import { LookupTableToolbarProps } from '../../schemas/lookup';

export const LookupTableToolbar: FC<LookupTableToolbarProps> = ({
  selectedRows,
  isReadyForComparison,
  onClickCompareSelected,
}) => {
  const handleClickCompareSelected = () => onClickCompareSelected(selectedRows.data.map(row => row.dataIndex));

  return (
    <Box paddingRight={3} paddingTop={0.75} paddingBottom={0.75}>
      <Tooltip title={'Compare Selected Accounts'}>
        <Button color="secondary" onClick={handleClickCompareSelected} startIcon={<CompareHorizontalIcon />}>
          {!isReadyForComparison && selectedRows.data.length < 2 ? 'Add to Comparison' : 'Compare'}
        </Button>
      </Tooltip>
    </Box>
  );
};
